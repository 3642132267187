<template>
    <div v-if="$role != 'admin'&& !isMinisterial() || hasPermissions('newsboard') " class="col-lg-6 col-md-12 col-xl-6 my-2">
        <div class="card">
            <div class="card-header text-initial">
                <h4><i class="far fa-newspaper"></i> {{$t('news.text')}}</h4>
            </div>
            <div class="card-body">
                <div v-if="Object.keys(dashboardData).length" class="ul-widget__body box-scroll">
                    <div v-for="(item, index) in dashboardData.newsEvents" :key="index" >
                        <div v-if="item.type == 'news'" class="d-flex justify-content-between border-bottom py-2">
                            <div>
                                <router-link :to="{name : 'NewsShow', state:  { id : item.id}}">
                                <a class="ul-widget4__title" href="#">  
                                <i class="fas fa-bullhorn"></i>  {{item.title.substring(0,15)+".."}} 
                                </a>
                                </router-link>
                            </div>
                            <div>
                            <router-link :to="{name : 'NewsShow', state:  { id : item.id}}">
                                <button class="btn btn-success mx-1">{{$t('news.text')}}</button>
                            </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="$role != 'admin' && !isMinisterial() || hasPermissions('events')"   class="col-lg-6 col-md-12 col-xl-6 my-2">
        <div class="card">
            <div class="card-header text-initial">
                <h4><i class="far fa-calendar-alt"></i> {{$t('events.text')}}</h4>
            </div>
            <div class="card-body">
                <div v-if="Object.keys(dashboardData).length" class="ul-widget__body box-scroll">
                    <div v-for="(item, index) in dashboardData.newsEvents" :key="index" >
                        <div v-if="item.type == 'event'" class="d-flex justify-content-between border-bottom py-2">
                            <div>
                                <router-link :to="{name : 'EventShow', state:  { id : item.id}}"> <a class="ul-widget4__title" href="#">
                                    <i class="fas fa-bullhorn"></i>  {{item.title.substring(0,30)+".."}} </a>
                                </router-link>
                            </div>
                            <div>
                            <router-link :to="{name : 'EventShow', state:  { id : item.id}}">
                                <button class="btn btn-success mx-1">{{$t('events.text')}}</button>
                            </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    export default {
        name: "news",
        data() {
            return {
                publicPath: process.env.BASE_URL,
            }
        },
        computed:{
            ...mapState('dashboard', {
                dashboardData : 'dashboardData',
            }),
        },
      methods: {
        hasPermissions(perm) {
          if(this.dashboardData.role == 'admin') {
            if (this.dashboardData.adminPerm == "full" ) {
              return true;
            } else {
              if(this.dashboardData.adminPerm) {
                return this.dashboardData.adminPerm.includes(perm);
              }else {
                return false;
              }
            }
          }else {
            return false;
          }

        },
      },
    }
</script>

<style scoped>
.box-scroll {
    overflow-y: auto;
    max-height: 300px;
}
</style>