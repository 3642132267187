<template>
  <header class="main-header bg-white d-flex justify-content-between align-items-center p-1">
    <div class="header-toggle m-0 p-0">
      <div class="menu-toggle mobile-menu-icon">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>


    <div v-if="Object.keys(dashboardData).length"  class="header-part-right">

      <div v-if="Object.keys(dashboardData).length">
        <span v-if="dashboardData.activatedModules.indexOf('branchesAct') > -1">{{dashboardData.userActiveBranchName}}</span>
      </div>
      <!-- Full screen toggle-->
      <div id="data_fullscreen">
         <i class="i-Full-Screen header-icon d-none d-sm-inline-block"></i> 
      </div>
      <div v-show="changeLanguage == 1" class="btn-group">
           <i class="fas fa-globe-americas text-muted header-icon" style="font-size:16px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></i> 
        <div v-if="languages && languages.length"  class="dropdown-menu dropdown-menu-right text-center" x-placement="top-start">
          <a v-for="language in languages" :key="language.id" @click.prevent="switchLocale(language)" class="dropdown-item text-start" href="#">{{ language.languageTitle }}</a>
        </div>
      </div>
      <!-- Grid menu Dropdown-->
<!--        <div class="dropdown dropleft">-->
<!--             <i class="i-Safe-Box text-muted header-icon" id="dropdownMenuButton" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> -->
<!--            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">-->
<!--                <div class="menu-icon-grid">-->
<!--                    <a href="#" class="btn-outline-success mb-2">  <i class="i-Shop-4"></i>  Home</a>-->
<!--                    <a href="#" class="btn-outline-success mb-2"> <i class="i-Library"></i>  UI Kits </a>-->
<!--                    <a href="#" class="btn-outline-success mb-2">  <i class="i-Drop"></i>  Apps</a>-->
<!--                    <a href="#" class="btn-outline-success mb-2"> <i class="i-File-Clipboard-File&#45;&#45;Text"></i>  Forms</a>-->
<!--                    <a href="#" class="btn-outline-success mb-2"> <i class="i-Checked-User"></i>  Sessions</a>-->
<!--                    <a href="#" class="btn-outline-success mb-2"> <i class="i-Ambulance"></i>  Support</a>-->
<!--                </div>-->
<!--            </div>-->
<!--      </div>-->

      <!-- Notification -->


      <div class="dropdown notification">
        <div @click="loadNotifications" class="badge-top-container cursor-pointer" role="button" id="dropdownNotification" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
          <span class="badge badge-primary pt-1 notification-num" v-if="notificationCount">  {{ notificationCount }}</span>
           <i class="i-Bell text-muted header-icon"></i> 
        </div>
        <!-- Notification dropdown -->
        <div class="dropdown-menu dropdown-menu-right notification-dropdown" aria-labelledby="dropdownNotification" data-perfect-scrollbar data-suppress-scroll-x="true">

          <div class="dropdown-item d-flex" v-for="(notification, index) in notificationData.notifications" :key="index" @click.prevent="redirectTo(notification)">
            <div class="notification-details flex-grow-1">
              <p class="m-0 d-flex align-items-center">
                <span>{{notification.message}}</span>
                <span class="flex-grow-1"></span>
              </p>
              <p class="text-small text-muted m-0"> <i class="fa mr-1" :class="notification.icon ?? 'i-Data-Power' "></i>  {{notification.created_at}}</p>
            </div>
          </div>
         <div>
           <button @click.prevent="showAllNotifications" class="btn btn-success w-100"> <i class="fa fa-eye"></i>  show all</button>
         </div>
        </div>
      </div>
      <!-- Notification End -->
      <!-- User avatar dropdown -->
      <div class="dropdown">
        <div class="user align-self-end">
            <div class="photo" id="userDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img v-if="notificationData.users && notificationData.users.image" height="36" :src="notificationData.users.image">
                <img v-else :src="`${publicPath}assets/images/faces/1.jpg`" height="36">
            </div>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
            <div class="dropdown-header" v-if="notificationData.users">
               <i class="i-Lock-User mr-1"></i> 
              {{ notificationData.users.fullName}}
            </div>
            <router-link class="dropdown-item" :to="{name : 'ProfileInfo',  state: {schoolName:$schoolName}}">{{$t('AccountSettings.text')}}</router-link>
<!--            <router-link class="dropdown-item" :to="{name : 'AccountChangeProfileData',  state: {schoolName:$schoolName}}">{{$t('AccountSettings.text')}}</router-link>-->
            <router-link v-if="$role == 'admin' && $role == 'parent' && $role == 'student'" class="dropdown-item" :to="{name : 'AccountInvoices',  state: {schoolName:$schoolName}}">{{$t('myInvoices.text')}}</router-link>
            <a class="dropdown-item" @click.prevent="logout"> {{$t('logout.text')}}</a>
          </div>
        </div>
      </div>



    </div>
  </header>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: "app-header",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
  created() {
    this.loadNotifications();
  },
  methods: {
    ...mapActions({
      loadDashboard: 'dashboard/loadDashboard',
      changeLanguageUniversal: 'dashboard/changeLanguageUniversal',
      loadNotifications: 'notification/loadNotifications',
    }),
    logout() {
      this.$store.dispatch('logout');
    },
    switchLocale(language) {
        document.getElementById('sidebarIcon').style.display = 'block';
        document.getElementById('sidebarMenu').style.display = 'none';

      this.$axios.post('accountSettings/profile', {'spec': 'defLang', 'value': language.id}).then(response => {
        if (response.data.status === "success") {
          this.loadDashboard()
          this.changeLanguageUniversal({i18n: this.$i18n, languageUniversal : language.languageUniversal})
          localStorage.setItem('menus',JSON.stringify(response.data.data));
          this.setDirection(language);
          this.$toast.success(response.data.message);
        } else {
          this.$toast.error(response.data.message);
        }
      });
    },
    redirectTo(item) {
      this.$axios.get('accountSettings/notification/' +item.id).then( response => {
        if (response.data.status == 'success') {
          this.loadNotifications()
          this.$router.push({'name' : item.link, state : {id : item.type_id}})
        }
      })
    },
    showAllNotifications() {
      this.$router.push({name : 'NotificationsViewAll'})
    },
    setDirection(language) {
      let htmlEl = document.querySelector("html");
      let direction = 'ltr';
      if (language.isRTL) {
        direction = 'rtl';
      }
      localStorage.setItem('directionTheme', direction);
      htmlEl.setAttribute('dir', direction);
      htmlEl.setAttribute('lang', language.languageUniversal);
    }
  },
  computed: {
    ...mapState('dashboard', {
      changeLanguage : 'allowedChangeLanguage',
      languages : 'languages',
      dashboardData : 'dashboardData',
    }),
    ...mapState('notification', {
      notificationData : 'notificationData',
      notificationCount : 'notificationCount',
    }),

  },
}
</script>