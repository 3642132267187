

export default {
    namespaced: true,
    state: {
        invoice : {},
    },
    mutations: {
        SET_INVOICE: (state, invoice) => {
            state.invoice = invoice;
        },
    },
    actions: {
        SET_INVOICE: ({commit}, invoice) => {
            commit('SET_INVOICE', invoice);
        },
    },
    getters: {
        GET_INVOICE: state => state.invoice,
    }
}
