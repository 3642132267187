<template>
  <div>

    <div class="login-lang">
      <div class="btn-group">
        <i class="fas fa-globe-americas header-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></i>
        <div  class="dropdown-menu dropdown-menu-right text-center" x-placement="top-start">
          <a v-for="language in languages" :key="language.id" @click.prevent="switchLocale(language)" class="dropdown-item text-start" href="#">{{ language.languageTitle }}</a>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-md-6">
        
        <div class="p-3">
          <h1 class="mb-3 text-18 font-weight-bold">{{$t('contactUs.text')}}</h1>
          <form @submit.prevent="senMessage" novalidate class="text-initial">
            
            <div class="row">
                <div class="col-sm">
                    <div class="form-group">
                      <label for="email">{{$t('name.text')}} <span class="text-danger">*</span></label>
                      <input class="form-control form-control-rounded" id="name" type="text" required v-model="form.name">
                    </div>
                    <div class="error" v-if="v$.form && v$.$dirty && v$.form.name.$errors.length">
                      <div v-for="error in v$.form.name.$errors" :key="error" style="color:red;">
                        <span v-if="error.$validator == 'required'">{{$t('requiredMsg.text')}}</span>
                      </div>
                    </div>
                </div>

                <div class="col-sm">
                    <div class="form-group">
                      <label for="email">{{$t('username.text')}}</label>
                      <input class="form-control form-control-rounded" id="name" type="text" required v-model="form.username">
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div class="col-sm">
                    <div class="form-group">
                      <label for="email">{{$t('phone.text')}} <span class="text-danger">*</span></label>
                      <input class="form-control form-control-rounded" id="phone" type="text" required v-model="form.phone">
                    </div>
                    <div class="error" v-if="v$.form && v$.$dirty && v$.form.phone.$errors.length">
                      <div v-for="error in v$.form.phone.$errors" :key="error" style="color:red;">
                        <span v-if="error.$validator == 'required'">{{$t('requiredMsg.text')}}</span>
                      </div>
                    </div>
                </div>

                <div class="col-sm">
                    <div class="form-group">
                      <label for="email">{{$t('email.text')}}</label>
                      <input class="form-control form-control-rounded" id="email" type="email" required v-model="form.email">
                    </div>
                </div>
            </div>
            
            <div class="form-group">
              <label for="subject">{{$t('SubjectMessage.text')}} <span class="text-danger">*</span></label>
              <input class="form-control form-control-rounded" id="subject" type="text" required v-model="form.subject">
            </div>
            <div class="error" v-if="v$.form && v$.$dirty && v$.form.subject.$errors.length">
              <div v-for="error in v$.form.subject.$errors" :key="error" style="color:red;">
                <span v-if="error.$validator == 'required'">{{$t('requiredMsg.text')}}</span>
              </div>
            </div>

            <div class="form-group">
              <label for="message">{{$t('message.text')}} <span class="text-danger">*</span></label>
              <textarea class="form-control form-control-rounded" id="message"  required v-model="form.message"></textarea>
            </div>
            <div class="error" v-if="v$.form && v$.$dirty && v$.form.message.$errors.length">
              <div v-for="error in v$.form.message.$errors" :key="error" style="color:red;">
                <span v-if="error.$validator == 'required'">{{$t('requiredMsg.text')}}</span>
              </div>
            </div>

            <vue-recaptcha :sitekey="sitekey"
                           size="normal"
                           theme="light"
                           hl="tr"
                           @verify="recaptchaVerified"
                           @expire="recaptchaExpired"
                           @fail="recaptchaFailed"
                           ref="vueRecaptcha">
            </vue-recaptcha>
            <div class="error" v-if="!isVerified && v$.$dirty">
              <div style="color:red;"><span>{{$t('requiredMsg.text')}}</span></div>
            </div>

            <button class="btn btn-rounded btn-primary btn-block mt-2 p-2"
                    :disabled="buttonStatus">  {{ $store.state.buttonStatus ? $t('pleaseWait.text') : $t('save.text') }} <i v-if="$store.state.buttonStatus" class="fa fa-spinner fa-spin"></i></button>
          </form>
          <div class="mt-2 text-initial text-center">
            <router-link class="text-muted" :to="{name : 'Login'}">
              <u>{{$t('loginToAccount.text')}}</u>
            </router-link>
          </div>
        </div>
      </div>

      <div class="col-md-6 text-center small-bg" :style="{backgroundSize:'cover' ,backgroundImage: 'url(' + `${publicPath}assets/images/photo-long-3.jpg `+ ')' }">
        <div  class="p-3 text-center">
          <div v-if="settings.siteLogo == 'text' || settings.siteLogo == 'siteName'" class="font-weight-bold">{{title}}</div>
          <img :src="`${publicPath}assets/images/ultimate-transparent.png`" width="150" alt="" class="mb-3">
          <img v-if="settings.siteLogo == 'image'" :src="settings.imageLight" width="150" alt="" class="rounded">
        </div>
      </div>

    </div>



  </div>
</template>


<script>
import { mapActions } from 'vuex';
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import vueRecaptcha from 'vue3-recaptcha2';

export default {
  setup() {
    return {v$: useVuelidate()}
  },
  components: {
    vueRecaptcha
  },
  data() {
    return {
      sitekey : process.env.VUE_SITE_KEY ?? '6LcfmhAdAAAAAJesgmm8iPyrz5EkEI_vqve3H1nV',
      isVerified : false,
      form: {
        name:'',
        username:'',
        email:'',
        subject:'',
        message:'',
        phone:'',
      },
      publicPath: process.env.BASE_URL,
      register: process.env.VUE_APP_REGISTER,
      checkLogin: false,
      checkLicenseInvalid: false,
      message: '',
      languages:[],
      settings:{
        siteLogo :"",
        settings:""
      },
      title:''
    }
  },
  validations() {
    return {
      form: {
        name:{required},
        phone:{required},
        subject:{required},
        message:{required},
      },

    }
  },

  mounted() {
    if (this.settings.contactUs == 1)  {
      this.$router.push({name: 'ContactUs'})
    }
    this.checkLicense();
  },

  methods: {
    recaptchaVerified(response) {
      if (response) {
        this.isVerified = true;
      } else {
        this.isVerified = false;
      }
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
    },
    ...mapActions({
      changeLanguageUniversal: 'dashboard/changeLanguageUniversal',
    }),
    switchLocale(language) {
      this.changeLanguageUniversal({i18n: this.$i18n, languageUniversal : language.languageUniversal})
      this.setDirection(language)
    },
    senMessage() {
      this.v$.$validate() // checks all inputs
      if (!this.v$.$error && this.isVerified) {
        this.$axios.post('service/contact-us', this.form).then(response => {
          this.buttonTitle = this.$t('save.text');
          this.buttonStatus = false;
          if (response.data.status === "success") {
            this.$toast.success(response.data.message);
            this.v$.$reset();
            this.form.name = "";
            this.form.username = "";
            this.form.email = "";
            this.form.subject = "";
            this.form.message = "";
            this.form.phone = "";
          } else {
            this.$toast.error(response.data.message);
          }
        });
      }
    },
    checkLicense() {
      this.$axios.get('/website/check')
          .then((response) => {
            console.log(response);
            if (response.data.message == 'Invalid Url') {
              this.checkLogin = false;
              this.checkLicenseInvalid = true;
              this.message = 'Invalid Url';
            } else {
              this.getInfo();
              this.checkLicenseInvalid = false;
            }
          });
    },
    getInfo() {
      this.$axios.get('/service/login/settings')
          .then((response) => {
            let lang = {
              languageUniversal : response.data.showLang,
              isRTL : response.data.isRTL
            };
            this.switchLocale(lang)
            this.languages = response.data.allLanguages;
            this.settings = response.data;
            if(this.settings.siteLogo == 'text') {
              this.title = this.settings.siteLogoAdditional;
            }
            if(this.settings.siteLogo == 'siteName') {
              this.title = this.settings.siteTitle;
            }
            let colorTheme = response.data.layoutColor;
            if(colorTheme == 'ultimate') {
              colorTheme = "gradient-purple-indigo";
            }
            let appAllWrap = document.querySelector(".app-all-wrap");
            let body = document.querySelector("body");
            appAllWrap.setAttribute('data-theme-color', colorTheme);
            body.setAttribute('data-theme-color', colorTheme);
            this.checkLogin = true;

          });
    },
    setDirection(language) {
      let htmlEl = document.querySelector("html");
      let direction = 'ltr';
      if (language.isRTL) {
        direction = 'rtl';
      }
      localStorage.setItem('directionTheme', direction);
      htmlEl.setAttribute('dir', direction);
      htmlEl.setAttribute('lang', language.languageUniversal);
    }
  },
  computed: {
    invalidData() {
      return this.$store.state.loggedIn
    },
    buttonStatus() {
      return this.$store.state.buttonStatus
    },
  }
}
</script>

<style>
.small-bg {
  background-position: bottom;
  background-size: cover;
}
.login-lang {position: fixed; right: 20px; top:20px; z-index: 9;}
[dir="rtl"] .login-lang {right: auto; left:20px}
.login-lang .fa-globe-americas {color: #fff; padding: 10px 20px; font-size: 20px;cursor: pointer;}
.auth-layout-wrap .auth-content {
    width: 100%;
    max-width: 900px;
}
</style>