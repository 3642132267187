<template>
    <div v-if="isDashboard">

<!--      <div v-show="showNewVersion" class="modal-dialog modal-lg" role="document">-->
<!--        <div class="modal-content">-->
<!--          <div class="modal-header">-->
<!--            <h4 class="modal-title m-0 pt-2" id="exampleModalLabel">{{ $t(`showOldSchoolVersion.text`) }}</h4>-->
<!--            <button type="button" @click="CloseNewVersionModel" class="close pt-2" data-dismiss="modal" aria-label="Close">-->
<!--              <span aria-hidden="true">&times;</span>-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="modal-footer justify-content-start">-->
<!--            <a  :href="oldSchoolLink+'?s='+schoolName" target="_blank"  class="btn btn-success" data-dismiss="modal">{{$t('showNow.text')}}</a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
        <div class="dashboard-page">
            <div v-if="Object.keys(dashboardData).length" class="main-content pt-4">
                <div class="d-flex justify-content-between">
                    <h3 class="text-initial m-0 p-2">{{$t('dashboard.text')}}
                      <div v-if="dashboardData.docManualsLinkActive == 1" class="d-inline-block input-group-append" data-hint>
                        <a @mouseover="helpDescriptionLink('Dashboard')"><i class="fas fa-exclamation-circle"></i></a>
                        <div class="hint text-theme text-center">
                          <p> {{description}}<br/> <a  :href="descriptionLink" target="_blank">  {{ $t('loadMore.text') }} </a></p>
                        </div>
                      </div>

                    </h3>
                    <Breadcrumb></Breadcrumb>
                </div>
                <div class="separator-breadcrumb border-top"></div>
                <Counter></Counter>

                <div class="container-canvas my-3">
                    <RadarChart v-if="isAdmin() || isStudent() || isTeacher()"></RadarChart>
                </div>

                <RadarChartStudent v-if="isParent()"></RadarChartStudent>
                <div class="row mt-4">
                    <attendance v-if="isStudent() || isParent()"></attendance>
                    <grades v-if="!isTeacher() && $role != 'HOD' && $role != 'ministerial'  && ($role != 'admin' || hasPermissions('schedules'))"></grades>
                </div>
                <div  class="mt-4" v-if="!isStudent() && dashboardData.sql_connection_status == '0' && !isTeacher() && dashboardData.activatedModules.indexOf('generalLedgerAct') > -1">
                    <invoice v-if="dashboardData.activatedModules.indexOf('generalLedgerAct') > -1"></invoice>
                </div>
                <div class="row mt-4">
                    <Students></Students>
                    <Teachers></Teachers>
                </div>
                <div class="row mt-4">
                    <News></News>
                </div>
                <div v-if="$role != 'admin' || hasPermissions('Messages')" class="mt-4">
                    <Messages></Messages>
                </div>
                <div v-if="$role != 'admin' || hasPermissions('Calender')" class="mt-4">
                    <Calendar></Calendar>
                </div>
            </div>
            <div class="sidebar-overlay open"></div>
            <div class="flex-grow-1"></div>
            <polls></polls>
        </div>
    </div>
    <router-view></router-view>
</template>

<script>
    import Breadcrumb          from './breadcrumb'
    import Counter             from './counter'
    import RadarChart          from './radar-chart'
    import Calendar            from './calendar'
    import Messages            from "./messages";
    import Students            from "./students";
    import Teachers            from "./teachers";
    import News                from "./news";
    import invoice             from "./invoice";
    import polls               from "./polls";
    import attendance          from "./attendance";
    import grades              from "./grades";
    import RadarChartStudent   from "./radar-chart-sudent";
    import {mapState}          from "vuex";

    export default {
        name: "dashboard",
        components: {
            News,
            Teachers,
            Students,
            Messages,
            Counter,
            Breadcrumb,
            RadarChart,
            Calendar,
            invoice,
            polls,
            attendance,
            grades,
            RadarChartStudent,
        },
        data() {
            return {
              oldSchoolLink: process.env.VUE_APP_OLD_SCHOOL_LINK,
              docManualsLink: process.env.VUE_APP_DESC_LINK,
              link: this.$route.meta.link,
              descriptionLink:'',
              description:'',
              showNewVersion:true,
              schoolName: this.$route.params.schoolName,
            }
        },
        methods: {
          CloseNewVersionModel() {
            this.showNewVersion =  !this.showNewVersion;


          },
          helpDescriptionLink(routeName) {
            this.$axios.get('description_link/'+routeName)
                .then(response => {
                  this.descriptionLink = "";
                  this.currentLang = response.data.currentLang;
                  this.description = response.data.childrenMenue.description
                  this.descriptionLink = this.docManualsLink+'/?lang='+this.currentLang;

                });
          },

          hasPermissions(perm) {
            if(this.dashboardData.role == 'admin') {
              if (this.dashboardData.adminPerm == "full" ) {
                return true;
              } else {
                if(this.dashboardData.adminPerm) {
                  return this.dashboardData.adminPerm.includes(perm);
                }else {
                  return false;
                }
              }
            }else {
              return false;
            }

          },

        },
        mounted() {
            let userAgent = navigator.userAgent || navigator.vendor || window.opera;
            const plugin = document.createElement("script");
            if (/android/i.test(userAgent)) { // if Android
                plugin.setAttribute("src", "/assets/js/mobile/android-webview.js");
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) { // if IOS
                plugin.setAttribute("src", "/assets/js/mobile/ios-webview.js");
            }
            plugin.async = true;
            document.head.appendChild(plugin);
        },
        created() {
            if (localStorage.getItem('token')) {
                this.$store.dispatch('dashboard');
            }
        },
        watch: {
            dashboardData(newD,oldD) {
                if (oldD.languageUniversal != newD.languageUniversal) {
                    console.log(newD.languageUniversal)
                    this.$i18n.locale = newD.languageUniversal;
                }
            }
        },
        computed: {
            isDashboard() {
                return this.$route.name === 'Dashboard'
            },
            ...mapState('dashboard', {
                dashboardData : 'dashboardData',
            }),
        },
    }
</script>