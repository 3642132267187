import Axios from "@/plugins/axios";

export default {
    namespaced: true,
    state: {
        currentTheme: '',
        currentDirection: '',
        dashboardData: {},
        polls: {},
        allowedChangeLanguage: 0,
        menus: [],
        languages : [],
    },
    mutations: {
        setDashboard(state, dashboardData) {
            state.dashboardData = dashboardData;
        },
        setActivePolls(state, responseData) {
            state.polls = responseData.polls;
        },
        setTheme(state, colorTheme) {
            state.currentTheme = colorTheme;
            let appAllWrap = document.querySelector(".app-all-wrap");
            let body = document.querySelector("body");
            let sidebarColor = document.querySelector(".sidebar-colors a.color");
            appAllWrap.setAttribute('data-theme-color', colorTheme);
            body.setAttribute('data-theme-color', colorTheme);
            if (sidebarColor !== null) {
                sidebarColor.classList.remove("active");
            }
            document.querySelector('[data-sidebar-class="sidebar-' + colorTheme + '"]').classList.add('active');
            document.querySelector('[data-sidebar-class="sidebar-' + colorTheme + '"]').click();
            // localStorage.setItem('themeColor', colorTheme)
        },
        setDirection(state, responseData) {
            let css = "";

            if(responseData.activateHelp ==  "1") {
                css = `[data-hint] {display:block}`;
            }else {
                css = `[data-hint] {display:none}`;
            }
            this.styleTag = document.createElement('style');
            this.styleTag.appendChild(document.createTextNode(css));
            document.head.appendChild(this.styleTag);

            let htmlEl = document.querySelector("html");
            let direction = 'ltr';
            document.getElementById('sidebarIcon').style.display = 'none';
            document.getElementById('sidebarMenu').style.display = 'block';
            if (responseData.isRTL) {
                direction = 'rtl';
                document.getElementById('sidebarIcon').style.display = 'none';
                document.getElementById('sidebarMenu').style.display = 'block';
            }
            state.currentDirection = direction;
            localStorage.setItem('directionTheme', direction);
            htmlEl.setAttribute('dir', direction);
            htmlEl.setAttribute('lang', responseData.languageUniversal);
        },
        setAllowedChangeLanguage(state, responseData) {
            state.allowedChangeLanguage = responseData.languageAllow
        },
        setMenus(state, menus) {
            state.menus = menus
        },
        setLanguages(state, languages) {
            state.languages = languages
        }
    },
    actions: {
        loadDashboard({commit}) {
           const schoolName = localStorage.getItem('schoolName');
           let apiUrl = 'dashboard';
           if (schoolName) {
               apiUrl = `dashboard?s=${schoolName}`
           }

            Axios.get(apiUrl).then(response => {
                    if (response != undefined) {
                        let themeColor = response.data.baseUser.defTheme == "" || response.data.baseUser.defTheme == null ? response.data.layoutColor : response.data.baseUser.defTheme;
                       if(themeColor == 'ultimate') {
                           themeColor = "gradient-purple-indigo";
                       }
                        console.log("themeColor",themeColor);

                        commit('setMenus', response.data.customMenu);
                        commit('setTheme', themeColor);
                        commit('setDirection', response.data);
                        commit('setAllowedChangeLanguage', response.data);
                        commit('setDashboard', response.data);
                        localStorage.setItem('dashboard', JSON.stringify(response.data))
                        commit('setActivePolls', response.data);
                        commit('setLanguages', response.data.languages);
                    }
                }
            ).catch(
                (error) => console.log(error)
            );
        },
        changeLanguageUniversal({commit}, payload) {
            payload.i18n.locale = payload.languageUniversal
            console.log(payload, 'i18n.localei18n.locale', commit)
        }
    }
}