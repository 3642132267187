import Axios from "@/plugins/axios";

export default {
    namespaced: true,
    state: {
        notificationData: {},
        notificationCount: 0,
    },
    mutations: {
        setNotificationData(state, data) {
            state.notificationData = data;
        },
        setNotificationCount(state, data) {
            state.notificationCount = data.count_unread_notifications;
        }
    },
    actions: {
        loadNotifications({commit}) {
            Axios.get('dashboard-info?s=' + localStorage.getItem('schoolName')).then(response => {
                commit('setNotificationData', response.data.data);
                commit('setNotificationCount', response.data.data);
            }).catch((error) =>
                console.log(error)
            );
        }
    }
}
