<template>
    <div class="card">
        <div class="card-body">
            <base-loader :start-loading="isLoading"></base-loader>
            
            <div class="row">
                <div class="col">
                    <select class="form-control" name="student" v-if="children.length" v-model="form.student" @change="getDataSet">
                        <option v-for="(child, index) in children" :value="child.id" :key="index">{{child.student}}</option>
                    </select>
                </div>
                <div class="col">
                    <select class="form-control" name="type" v-model="form.type" @change="changeType()">
                        <option value="a">{{ $t('allSubjects.text') }}</option>
                        <option value="s">{{ $t('sammary.text') }}</option>
                    </select>
                </div>
            </div>
            <div v-if="barChart.data.labels.length && barChart.data.datasets.length" class="m-auto text-center" style="max-width: 600px">
                <vue3-chart-js v-bind="{ ...barChart }"/>
            </div>
            
        </div>
    </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import BaseLoader from "@/components/Shared/BaseLoader";

export default {
  name: "App",
  components: {
    BaseLoader,
    Vue3ChartJs,
  },
  data() {
    return {
      children: [],
      form : {
        student : '',
        type : 'a',
      },
      isLoading: true,
      barChart: {
        type: "radar",
        options: {
          min: 0,
          max: 100,
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
        },
        data: {
          labels: [],
          datasets: [],
        },
      }
    }
  },
  created() {
    this.getChildren();
    this.getDataSet();
  },
  methods: {
    getDataSet() {
      this.isLoading = true
      this.barChart.data.labels = [];
      this.barChart.data.datasets = [];
      this.$axios.get('charts/examslist?child='+this.form.student).then(response => {
        if (response.data.data.indicator && response.data.data.indicator.length) {
          this.barChart.data.labels = response.data.data.indicator.map(item => {
            return item.name
          });
        }
        if (response.data.data.data && response.data.data.data.length) {
          this.barChart.data.datasets = response.data.data.data.map((item) => {
            return {
              label: item.name,
              data: item.value,
              fill: true,
              backgroundColor: this.generateRandomColor,
            }
          })
        }
        this.isLoading = false
      })
    },
    generateRandomColor() {
      const randomNum = Math.round(0xffffff * Math.random());
      return 'rgb(' + (randomNum >> 16) + ',' + (randomNum >> 8 & 255) + ',' + (randomNum & 255) + ')';
    },
    getChildren() {
      this.$axios.get('charts/data').then(response => {
        this.children = response.data.childern;
        if (this.children && this.children.length) {
          this.form.student = this.children[0].id
        }
      });
    }
  },
};
</script>