import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
// import { VuelidatePlugin } from '@vuelidate/core'
import Swal from 'sweetalert2'
import $ from 'jquery'; // <-to import jquery
import VueYouTubeEmbed from 'vue-youtube-embed'
// import 'v-calendar/dist/style.css';
import 'v-calendar/style.css';

import VueHtml2Canvas from 'vue-html2canvas';

import axios from "./plugins/axios";
import store from './store';
import i18n from './i18n'

import Toaster from '@meforma/vue-toaster';
import Pagination from 'v-pagination-3';
import moment from 'moment'
import FileUpload from 'vue-upload-component';
import { VueEditor } from "vue3-editor";
import { serialize } from 'object-to-formdata';
import {  Calendar, DatePicker } from 'v-calendar';
import BaseLoader from '@/components/Shared/BaseLoader';
import TheMessageValidation from '@/components/Shared/TheMessageValidation';
import TheMessageValidationLang from '@/components/Shared/TheMessageValidationLang';

const serializeOptios = {
    /**
     * include array indices in FormData keys
     * defaults to false
     */
    indices: true,

    /**
     * treat null values like undefined values and ignore them
     * defaults to false
     */
    nullsAsUndefineds: false,

    /**
     * convert true or false to 1 or 0 respectively
     * defaults to false
     */
    booleansAsIntegers: false,

    /**
     * store arrays even if they're empty
     * defaults to false
     */
    allowEmptyArrays: false,
};

let app = createApp(App)
app.config.globalProperties.$ = $;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$role = localStorage.getItem('role');
app.config.globalProperties.$role = localStorage.getItem('role');
app.config.globalProperties.$dhashboardData = JSON.parse(localStorage.getItem('dashboard'));
app.config.globalProperties.$dashboardData = JSON.parse(localStorage.getItem('dashboard'));
app.config.globalProperties.$schoolName = localStorage.getItem('schoolName');
app.config.globalProperties.$menu = JSON.parse(localStorage.getItem('menus'));
app.config.globalProperties.$serialize = serialize;
app.config.globalProperties.$serializeOptios = serializeOptios;
app.config.globalProperties.$Swal = Swal;
app.config.globalProperties.$API = process.env.VUE_APP_API;
app.config.globalProperties.VueYouTubeEmbed = VueYouTubeEmbed;
app.config.globalProperties.html2canvas = VueHtml2Canvas;


//The number of lenght of the validation errors msg
app.config.globalProperties.$minValueForMobile = 7; //min lenght for mobile
app.config.globalProperties.$maxValueForMobile = 20; //max lenght for mobile
app.config.globalProperties.$minValueForInput = 2; //min lenght for input
app.config.globalProperties.$minValueForTexterea = 9; //min lenght for texterea
app.config.globalProperties.$maxValueForInput = 191; //max lenght for input
app.config.globalProperties.$maxValueForTextArea = 5000; //max lenght for texterea
app.config.globalProperties.$minPasswordValue = 8; //min lenght for password



app.config.globalProperties.$moment=moment;
// console.log(app.config.globalProperties.$dhashboardData);
app.use(router).use(store)
    // .use(VuelidatePlugin)
    .use(Toaster,{  position: "top-right",duration:4000})
    // Setup the plugin with optional defaults
    .use(i18n);

app.component('pagination',Pagination);
app.component('VueEditor',VueEditor);
app.component('FileUpload',FileUpload);
app.component('Calendar', Calendar);
app.component('DatePicker', DatePicker);
app.component('base-loader', BaseLoader);
app.component('message-validation', TheMessageValidation);
app.component('message-validation-lang', TheMessageValidationLang);
app.mount('#app');

//global functions
app.mixin({
    methods: {
        isAdmin() {
            return this.$role == 'admin';
        },
        isTeacher() {
            return this.$role == 'teacher';
        },
        isManager() {
            return this.$role == 'manager';
        },
        isStudent() {
            return this.$role == 'student';
        },
        isAdminOrTeacherOrManager() {
            return this.$role == 'admin' || this.$role == 'teacher' || this.$role == 'manager';
        },
        isParent() {
            return this.$role == 'parent';
        },
        isHOD() {
            return this.$role == 'HOD';
        },
        isMinisterial() {
            return this.$role == 'ministerial';
        },
        isCurrentYearEqualSelectedYear() {
            this.dashboardData.currentSessionAcademicYear ==  this.dashboardData.defaultAcademicYear
        },
        isMultiDepartment() {
            this.$dashboardData.multiDepartment
        },
        // hasPermissions(perm) {
        //     if (this.$dashboardData.adminPerm == "full") {
        //         return true;
        //     } else {
        //         return this.$dashboardData.adminPerm.includes(perm);
        //     }
        // },
        systemNumber() {
            this.$dashboardData.system_number;
        },
    }
})


