<template>
    <div class="card mb-4">
        <div class="card-body pb-0">
            <div v-if="!isLoading" class="text-center m-auto" style="max-width: 600px">
                <vue3-chart-js v-bind="{ ...barChart }"/>
            </div>
            <div class="pb-5" v-if="isLoading">
                <base-loader :start-loading="isLoading"></base-loader>
            </div>
        </div>
    </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import BaseLoader from "@/components/Shared/BaseLoader";

export default {
  name: "App",
  components: {
    BaseLoader,
    Vue3ChartJs,
  },
  data() {
    return {
      isLoading: true,
      barChart: {
        type: "radar",
        options: {
          min: 0,
          max: 100,
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
        },
        data: {
          labels: [],
          datasets: [],
        },
      }
    }
  },
  created() {
    this.getDataSet();
  },
  methods: {
    getDataSet() {
      this.$axios.get('charts/examslist').then(response => {
        this.barChart.data.labels = response.data.data.indicator.map(item => {
          return item.name
        });
        if(response.data.data.data) {
          this.barChart.data.datasets = response.data.data.data.map((item) => {
            return {
              label: item.name,
              data: item.value + 1,
              fill: true,
              backgroundColor: this.generateRandomColor,
              // backgroundColor: 'rgba(255, 99, 132, 0.2)',
              // borderColor: this.generateRandomColor,
            }
          })
        }else {
          this.barChart.data.datasets = [];
        }

        this.isLoading = false
      })
    },
    generateRandomColor() {
      const randomNum = Math.round(0xffffff * Math.random());
      return 'rgb(' + (randomNum >> 16) + ',' + (randomNum >> 8 & 255) + ',' + (randomNum & 255) + ')';

    }
  },
};
</script>