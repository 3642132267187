<template>
    <div v-if="Object.keys(dashboardData).length">
        <div v-if="dashboardData.activatedModules.indexOf('messagesAct') > -1 && !isMinisterial()" class="card">

            <div class="card-header text-initial">
                <h4><i class="fas fa-comments"></i> {{$t('messages.text')}}</h4>
            </div>
            <div class="card-body">
                <div class="ul-widget__body box-scroll text-initial">
                    <div class="ul-widget3">
                        <div class="ul-widget3-item" v-for="(message, index) in dashboardData.messages" :key="index">
                            <div class="ul-widget3-header m-0 p-0">
                                <router-link :to="{name: 'ComposeMessage' , state: {id:message.id} }">
                                    <div class="ul-widget4__title t-font-bolder">{{message.fullName}}</div>
                                    <div class="ul-widget-notification-item-time">{{message.lastMessageDate}}</div>
                                    <small>{{message.lastMessage}}</small>
                                </router-link>
                                <i class="fa fa-user-circle fa-2x py-2 px-3"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
name: "messages",
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  computed:{
    ...mapState('dashboard', {
      dashboardData : 'dashboardData',
    }),
  },
}
</script>

<style scoped>
.box-scroll {
  overflow-y: auto;
  max-height: 300px;
}
</style>