import {createStore} from 'vuex'
import axios from "axios";
import router from "../router";
import Axios from "../plugins/axios";
import dashboard from './modules/dashboard'
import notification from './modules/notification'
import Invoice from './modules/Invoice'

export default createStore({
    state: {
        token: null,
        fullName: '',
        role: '',
        dashboardData: {},
        route: null,
        loggedIn: true,
        notificationData : {},
        buttonStatus : false,
    },
    mutations: {
        SET_ROUTE(state, route) {
            state.route = route;
        },
        authUser(state, userData) {
            state.token = userData.token;
        },
        user(state, userData) {
            state.fullName = userData.fullName;
            state.role = userData.role;
        },
        dashboard(state, dashboardData) {
            state.dashboardData = dashboardData;

        },
        clearAuthData(state) {
            state.token = null;
        },
        loginFailureData(state, loggedIn) {
            state.loggedIn = loggedIn;
        },
        notificationData(state, data) {
            state.notificationData = data;
        },
        changeButtonStatus(state, isFinished) {
            state.buttonStatus = isFinished;
        }
    },
    modules: {
        dashboard,
        notification,
        Invoice,
    },
    actions: {
        login({commit}, authData) {
            commit('changeButtonStatus', true)
            axios.post('login', {email: authData.email, password: authData.password}).then(response => {
                if (response.data.message != 'License Expired') {
                    const token = 'Bearer' + ' ' + response.data.access_token;
                    const now = new Date();
                    const expirationDate = new Date(now.getTime() + response.data.expires_in);
                    localStorage.setItem('token', token)
                    localStorage.setItem('expirationDate', expirationDate)
                    localStorage.setItem('role', response.data.role)
                    localStorage.setItem('storageName', authData.email)



                    // localStorage.setItem('menus',JSON.stringify(response.data.menu))
                    commit('authUser', {token: token});
                    commit('loginFailureData', true)
                    let data = [];
                    if (localStorage.getItem('tokens')) {
                        data = JSON.parse(localStorage.getItem('tokens'));
                    }
                    data.push({
                        'schoolName': localStorage.getItem('schoolName'),
                        'token': token,
                        'role': response.data.role,
                        'expirationDate': expirationDate,
                        'storageName':authData.email
                    });
                    localStorage.setItem('tokens', JSON.stringify(data))
                    Axios.get('dashboard', {headers: {Authorization: token}}).then(response => {
                            if (response != undefined) {
                                localStorage.setItem('dashboard', JSON.stringify(response.data))
                                const themeColor = response.data.baseUser.defTheme ?? response.data.layoutColor
                                localStorage.setItem('themeColor', themeColor)
                                commit('dashboard', response.data);
                                window.location = "/" + localStorage.getItem('schoolName');
                            }
                        }
                    ).catch(
                        (error) => console.log(error)
                    );
                } else {
                    localStorage.removeItem('token')
                    localStorage.removeItem('expirationDate')
                    commit('changeButtonStatus', false)
                    router.push({path: '/' + localStorage.getItem('schoolName') + '/invalid-license'})
                }
            }).catch(error => {
                commit('changeButtonStatus', false)
                console.log(error.response)
            });

        },
        profile({commit, getters}) {
            let schoolName = getters.getRoute;
            console.log(schoolName);
            Axios.post('me?s=').then((response) => {
                    if (response != undefined) {
                        localStorage.setItem('fullName', response.data.fullName)
                        localStorage.setItem('role', response.data.role)
                        commit('user', {
                            fullName: response.data.fullName,
                            role: response.data.role
                        });
                    }
                }
            ).catch(
                (error) => console.log(error)
            );
        },
        dashboard({commit}) {
            Axios.get('dashboard').then(response => {
                    if (response != undefined) {
                        // layoutColor
                        if(response.data.baseUser && response.data.layoutColor) {
                            const themeColor = response.data.baseUser.defTheme ?? response.data.layoutColor
                            localStorage.setItem('themeColor', themeColor)
                        }
                        localStorage.setItem('dashboard', JSON.stringify(response.data))
                        commit('dashboard', response.data);
                    }
                }
            ).catch(
                (error) => console.log(error)
            );
        },
        tryAutoLogin({commit}) {
            const token = localStorage.getItem('token');
            if (!token) {
                return
            }
            const expirationDate = localStorage.getItem('expirationDate');
            const now = new Date();
            if (now <= expirationDate) {
                return;
            }
            commit('authUser', {
                token: token
            })
            const fullName = localStorage.getItem('fullName');
            const role = localStorage.getItem('role');
            commit('user', {
                fullName: fullName,
                role: role
            })
        },
        logout({commit}, check = true) {
            console.log("check222222222", check);
            Axios.post('logout').then((response) => {
                    console.log(response)
                    localStorage.removeItem('token')
                    localStorage.removeItem('expirationDate')
                    commit('clearAuthData');
                    if (localStorage.getItem('tokens')) {
                        let tokens = JSON.parse(localStorage.getItem('tokens'));
                        let index = tokens.findIndex(record => record.schoolName === localStorage.getItem('schoolName'));
                        if (index != -1) {
                            tokens.splice(index, 1);
                            localStorage.setItem('tokens', JSON.stringify(tokens))
                        }
                    }
                    window.location = "/" + localStorage.getItem('schoolName') + '/login';
                }
            ).catch(
                (error) => console.log(error)
            );
        },
        loginFailure({commit}) {
            commit('loginFailureData', false)
        },
        loadNotifications({commit}) {
            Axios.get('dashboard-info?s='+localStorage.getItem('schoolName')).then(response => {
                commit('notificationData', response.data.data);
            }).catch((error) =>
                console.log(error)
            );
        }
    },
})
