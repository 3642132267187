import { createI18n } from 'vue-i18n'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */


import en from './locales/en.json'
import ar from './locales/ar.json'
import tr from './locales/tr.json'
import fr from './locales/fr.json'

export const defaultLocale = 'en'

export const messages = {
  en: en,
  ar: ar,
  fr: fr,
  tr: tr,

}
export default createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  globalInjection: true,
  messages,
  localeDir: 'locales',
  enableLegacy: false,
  runtimeOnly: false,
  compositionOnly: false,
  fullInstall: true

})
