<template>
    <div v-if="Object.keys(dashboardData).length">
    
        <div v-if="isAdmin() || isManager() || isHOD()">
            <div class="row dashboard-counter">
                <div class="col" style="min-width:250px">
                    <div class="card card-icon mb-4">
                    <router-link :to="{name: 'Grades'}">
                        <div class="card-body text-center p-3">
                            <i class="i-Home-2 text-theme"></i>
                            <p class="text-muted mt-1 mb-1">{{$t('classes.text')}}</p>
                            <p class="text-24 line-height-1 text-theme m-0">{{dashboardData.stats.classes || 0 }}</p>
                        </div>
                    </router-link>
                    </div>
                </div>
                <div class="col" style="min-width:250px">
                    <div class="card card-icon mb-4">
                    <router-link :to="{name: 'Teachers'}">
                        <div class="card-body text-center p-3">
                            <i class="i-Checked-User text-theme"></i>
                            <p class="text-muted mt-1 mb-1">{{$t('teachers.text')}}</p>
                            <p class="text-24 line-height-1 text-theme m-0">{{ dashboardData.stats.teachers || 0 }}</p>
                        </div>
                    </router-link>
                    </div>
                </div>
                <div class="col" style="min-width:250px">
                    <div class="card card-icon mb-4">
                    <router-link :to="{name: 'Parents'}">
                        <div class="card-body text-center p-3">
                            <i class="i-Checked-User text-theme"></i>
                            <p class="text-muted mt-1 mb-1">{{$t('parents.text')}}</p>
                            <p class="text-24 line-height-1 text-theme m-0">{{ dashboardData.stats.parents || 0 }}</p>
                        </div>
                    </router-link>
                    </div>
                </div>
                <div class="col" style="min-width:250px">
                    <div class="card card-icon mb-4">
                    <router-link :to="{name: 'Students'}">
                        <div class="card-body text-center p-3">
                            <i class="i-Checked-User text-theme"></i>
                            <p class="text-muted mt-1 mb-1">{{$t('students.text')}}</p>
                            <p class="text-24 line-height-1 text-theme m-0">{{ dashboardData.stats.students || 0 }}</p>
                        </div>
                    </router-link>
                    </div>
                </div>
                <div class="col" style="min-width:250px">
                    <div class="card card-icon mb-4">
                    <router-link :to="{name: 'Messages'}">
                        <div class="card-body text-center p-3">
                            <i class="i-Bell text-theme"></i>
                            <p class="text-muted mt-1 mb-1">{{$t('messages.text')}}</p>
                            <p class="text-24 line-height-1 text-theme m-0">{{ dashboardData.stats.newMessages || 0 }}</p>
                        </div>
                    </router-link>
                    </div>
                </div>
                <div v-if="dashboardData.activatedModules.indexOf('generalLedgerAct') > -1 &&  isAdmin()" class="col" style="min-width:250px">
                    <div class="card card-icon mb-4">
                        <div class="card-body text-center p-3">
                            <i class="i-Gear text-theme"></i>
                            <p class="text-muted mt-1 mb-1">{{$t('subpaidInvoce.text')}}</p>
                            <p class="text-24 line-height-1 text-theme m-0">{{ dashboardData.stats.subpaidInvoceCount || 0 }}</p>
                        </div>
                    </div>
                </div>
                <div   v-if="dashboardData.activatedModules.indexOf('generalLedgerAct') > -1 && isAdmin()" class="col" style="min-width:250px">
                    <div class="card card-icon mb-4">
                        <div class="card-body text-center p-3">
                            <i class="i-Money-Bag text-theme"></i>
                            <p class="text-muted mt-1 mb-1">{{$t('Invoices.text')}}</p>
                            <p class="text-24 line-height-1 text-theme m-0">{{ dashboardData.stats.paidInvoceCount || 0 }}</p>
                        </div>
                    </div>
                </div>
                <div  v-if="dashboardData.activatedModules.indexOf('generalLedgerAct') > -1 && isAdmin()" class="col" style="min-width:250px">
                    <div class="card card-icon mb-4">
                        <div class="card-body text-center p-3">
                            <i class="i-Money-Bag text-theme"></i>
                            <p class="text-muted mt-1 mb-1">Due {{$t('dueInvoices.text')}}</p>
                            <p class="text-24 line-height-1 text-theme m-0">{{ dashboardData.stats.unpaidInvoceCount || 0 }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">

            <div class="col-md-6">
                <div class="card card-profile-1 mb-4 h-100">
                    <div class="card-body d-flex text-center">
                        <div class="m-auto">
                            <div class="avatar box-shadow-2 mb-3"><img :src="dashboardData.baseUser.image" alt="" /></div>
                            <h5 class="m-0">{{ dashboardData.baseUser.fullName }}</h5>
                            <p class="m-0">{{ $t(dashboardData.role+'.text') }}</p> 
                            <small>{{ dashboardData.baseUser.email }}</small>
                            
                            <div class="row">
                                <div class="col my-1 p-1">
                                    <router-link :to="{name : 'AccountChangeProfileData',  state: {schoolName:$schoolName}}">
                                        <a class="text-white btn btn-success py-2 d-block"> <i class="fa fa-cogs"></i> {{$t('AccountSettings.text')}}</a>
                                    </router-link>
                                </div>
                                <div v-if="dashboardData.activatedModules.indexOf('messagesAct') > -1 && !isMinisterial()"  class="col my-1 p-1">
                                    <router-link :to="{name : 'Messages',  state: {schoolName:$schoolName}}">
                                        <a class="text-white btn btn-success py-2 d-block"> <i class="fa fa-comments"></i> {{$t('messages.text')}}</a>
                                    </router-link>
                                </div>
                                <div class="col my-1 p-1"  >
                                    <a class="text-white btn btn-success py-2 d-block" @click.prevent="$store.dispatch('logout');"> <i class="fa fa-power-off"></i> {{$t('logout.text')}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card h-100">
                    <div class="card-header py-4">
                        <h3>{{ $t('quicklinks.text')}}</h3>
                    </div>
                    <div class="card-body d-flex text-center">
                        <div class="m-auto w-100">
                            <div v-if="isAdmin() || isHOD() || isManager()" class="row">
                                <div v-if="dashboardData.activatedModules.indexOf('newsboardAct') > -1  && (hasPermissions('newsboard')  || isHOD() || isManager() )" class="col my-1 p-1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'News'}">
                                        <i class="fa fa-cogs"></i> {{$t('newsboard.text')}}
                                    </router-link>
                                </div>
                                <div v-if="dashboardData.activatedModules.indexOf('eventsAct') > -1 && (hasPermissions('events') || isHOD() || isManager())" class="col my-1 p-1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'Event'}">
                                        <i class="fa fa-cogs"></i> {{$t('events.text')}}
                                    </router-link>
                                </div>
                                <div v-if="dashboardData.activatedModules.indexOf('classSchAct') > -1 && (hasPermissions('classSch') || isHOD() || isManager()) " class="col my-1 p-1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'ClassSchedules'}">
                                        <i class="fa fa-cogs"></i> {{$t('ClassSchedule.text')}}
                                    </router-link>
                                </div>
                                <div v-if="dashboardData.activatedModules.indexOf('mailSmsAct') > -1 && (hasPermissions('mailsms') || isHOD())" class="col my-1 p-1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'MailsmsTemplates'}">
                                        <i class="fa fa-comments"></i> {{$t('mailsms.text')}}
                                    </router-link>
                                </div>
                                <div v-if="dashboardData.activatedModules.indexOf('attendanceAct') > -1 && (hasPermissions('Attendance') || isHOD())" class="col my-1 p-1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'studentAttendance'}">
                                        <i class="fa fa-comments"></i> {{$t('Attendance.text')}}
                                    </router-link>
                                </div>
                                <div v-if="dashboardData.activatedModules.indexOf('reportsAct') > -1 && (hasPermissions('Reports') || isHOD())" class="col my-1 p-1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'Report'}">
                                        <i class="fa fa-file"></i> {{$t('Reports.text')}}
                                    </router-link>
                                </div>
                                <div v-if="hasPermissions('generalSettings') || !isHOD() && !isManager()" class="col my-1 p-1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'Settings'}">
                                        <i class="fa fa-file"></i> {{$t('generalSettings.text')}}
                                    </router-link>
                                </div>
                            </div>

                            <div v-if="dashboardData.activatedModules.indexOf('attendanceAct') > -1 && (isTeacher() || isHOD())" class="row">
                                <div class="col my-1 p-1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'studentAttendance'}">
                                        <i class="fa fa-cogs"></i> {{$t('Attendance.text')}}
                                    </router-link>
                                </div>
<!--                                <div class="col my-1 p-1">-->
<!--                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'OnlineExams'}">-->
<!--                                        <i class="fa fa-cogs"></i> {{$t('examsList.text')}}-->
<!--                                    </router-link>-->
<!--                                </div>-->
                                <div class="col my-1 p-1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'ExamsList'}">
                                        <i class="fa fa-cogs"></i> {{$t('examsList.text')}}
                                    </router-link>
                                </div>
                                <div class="col my-1 p-1" v-if="dashboardData.activatedModules.indexOf('assignmentsAct') > -1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'Assignments'}">
                                        <i class="fa fa-cogs"></i> {{$t('Assignments.text')}}
                                    </router-link>
                                </div>
                                <div class="col my-1 p-1" v-if="dashboardData.activatedModules.indexOf('onlineexamsAct') > -1 ">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'OnlineExams'}">
                                        <i class="fa fa-cogs"></i> {{$t('onlineExams.text')}}
                                    </router-link>
                                </div>
                            </div>

                            <div v-if="isStudent()" class="row">
                                <div class="col my-1 p-1" v-if="dashboardData.activatedModules.indexOf('classSchAct') > -1" >
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'ClassSchedules'}">
                                        <i class="fa fa-cogs"></i> {{$t('ClassSchedule.text')}}
                                    </router-link>
                                </div>
                                <div class="col my-1 p-1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'ExamsList'}">
                                        <i class="fa fa-cogs"></i> {{$t('examsList.text')}}
                                    </router-link>
                                </div>
                                <div class="col my-1 p-1" v-if="dashboardData.activatedModules.indexOf('assignmentsAct') > -1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'Assignments'}">
                                        <i class="fa fa-cogs"></i> {{$t('Assignments.text')}}
                                    </router-link>
                                </div>
                                <div class="col my-1 p-1" v-if="dashboardData.activatedModules.indexOf('onlineexamsAct') > -1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'OnlineExams'}">
                                        <i class="fa fa-cogs"></i> {{$t('onlineExams.text')}}
                                    </router-link>
                                </div>
                            </div>

                            <div v-if="isParent() || isHOD()" class="row">
                                <div class="col my-1 p-1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'Students'}">
                                        <i class="fa fa-users"></i> {{$t('students.text')}}
                                    </router-link>
                                </div>
                                <div class="col my-1 p-1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'ExamsList'}">
                                        <i class="fa fa-users"></i> {{$t('examsList.text')}}
                                    </router-link>
                                </div>
                            </div>
                            <div v-if="isMinisterial()" class="row">
                                <div class="col my-1 p-1" >
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'MinisterialSupervisorEvaluation'}">
                                        <i class="fa fa-users"></i> {{$t('ministerialSupervisorEvaluation.text')}}
                                    </router-link>
                                </div>
                                <div class="col my-1 p-1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'Teachers'}">
                                        <i class="fa fa-users"></i> {{$t('listTeachers.text')}}
                                    </router-link>
                                </div>
                                <div class="col my-1 p-1" v-if="dashboardData.activatedModules.indexOf('classSchAct') > -1"> 
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'ClassSchedules'}">
                                        <i class="fa fa-users"></i> {{$t('classSch.text')}}
                                    </router-link>
                                </div>
                                    <div class="col my-1 p-1" v-if="dashboardData.activatedModules.indexOf('calendarAct') > -1">
                                    <router-link class="btn btn-success py-2 d-block" :to="{name : 'Calender'}">
                                        <i class="fa fa-users"></i> {{$t('Calender.text')}}
                                    </router-link>
                                </div>
                             
                            </div>
                        </div>
                        

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
name: "counter",
  computed:{
    ...mapState('dashboard', {
      dashboardData : 'dashboardData',
    }),
  },
  methods:{
    hasPermissions(perm) {
      if(this.dashboardData.role == 'admin') {
        if (this.dashboardData.adminPerm == "full" ) {
          return true;
        } else {
          if(this.dashboardData.adminPerm) {
            return this.dashboardData.adminPerm.includes(perm);
          }else {
            return false;
          }
        }
      }else {
        return false;
      }

    },
  }
}
</script>

<style scoped>
.avatar img {
    height: 100%;
}
.dashboard-counter .card-body:hover {
    background: #f4f4f4;
    border-radius: 10px;
}
.dark-theme .dashboard-counter .card-body:hover {
    background: #181e3f;
    border-radius: 10px;
} 
.dark-theme .dashboard-counter .text-theme {
    color: #c2c6dc
} 
</style>