
import axios from 'axios'
import router from "../router";
import store from '../store'

axios.defaults.baseURL =  process.env.VUE_APP_API;
if(localStorage.getItem('token') != 'Bearer undefined' && localStorage.getItem('token')) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem('token');
}
axios.defaults.headers.common["Content-Type"] = localStorage.getItem('multipart/form-data');

let schoolName = localStorage.getItem('schoolName');

axios.interceptors.response.use((response) => {
    if(response.status === 401) {
        //add your code
        alert("You are not authorized");
    }
    return response;
}, (error) => {
    if (error.response) {
        schoolName = router.currentRoute._value.params.schoolName;
        localStorage.setItem('schoolName',schoolName);
        if(error.response.data.message == 'Token has expired') {
            this.$store.dispatch('logout');
        }
        if(error.response.data.message == 'Token Signature could not be verified.') {
            this.$store.dispatch('logout');
        }
            if(error.response.data.status == 'Authorization Token not found') {
                this.$store.dispatch('logout');
            }
        if(error.response.data.error == 'Unauthorized') {
            store.dispatch('loginFailure');
        }
    }
});

export default axios;



