<template>
  <div v-if="polls" class="row">
    <div class="col-md-12">
      <div class="card mb-4">
        <div class="card-body text-center">
          <h4 class="card-title">{{ polls.title }}</h4>
          <div v-if="polls.view == 'vote'">
            <div v-for="(value, index) in polls.items" class="col-md-2" :key="index">
              <label class="radio radio-success">
                <input type="radio" name="selected" v-model="polls.selected" :value="value.title">
                <span>{{ value.title }}</span><span class="checkmark"></span>
              </label>
            </div>
            <textarea v-if="polls.selected" v-model="polls.comment" class="form-control" :placeholder="$t('addComment.text')"></textarea>
          </div>

          <div class="form-group p-4" v-if="polls.view == 'results'">
            <div v-for="(value, index) in polls.items" class="radio" :key="index">
              {{ value.title }} - {{ value.perc }}%
              <div class="progress progress-striped">
                <div class="progress-bar bg-info progress-bar-striped" role="progressbar" aria-valuenow="40"
                     aria-valuemin="0" aria-valuemax="100" :style="{width: value.perc + '%'}"></div>
              </div>
              <br/>
            </div>
            <div class="text-center">{{ $t('totalVotes.text') }} : {{ polls.totalCount }}</div>
          </div>

          <div v-if="!polls.voted" class="mt-4 mb-3">
            <button @click="polls.view = 'vote'" v-if="polls.view == 'results'" type="button" class="pull-right btn btn-info mr-3">
              {{ $t('Return.text') }}
            </button>
            <button @click="polls.view = 'results'" v-if="polls.view == 'vote'" type="button" class="pull-right btn btn-primary mr-3">
              {{ $t('votes.text') }}
            </button>
            <button v-if="polls.view == 'vote'" @click.prevent="savePollVote()" :disabled="!polls.selected" class="pull-right btn btn-primary mr-3">
              {{ $t('votePoll.text') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: "polls",
  data() {
    return {}
  },
  created() {
    this.loadDashboard();
  },
  methods: {
    savePollVote() {
      this.$axios.post('dashboard/polls', this.polls).then( response => {
        this.polls = response.data
      })
    },
    ...mapActions({
      loadDashboard: 'dashboard/loadDashboard',
    })
  },
  computed: {
    ...mapState('dashboard', {
      polls : 'polls'
    }),
  }
}
</script>

<style scoped>

</style>