<template>
  <div class="app-footer">
    <div class="row">

      <div class="col-md-9">
        <img class="bg-white rounded" :src="`${publicPath}assets/images/ultimate-transparent.png`" width="100" alt="">
        {{$t('AllRightsReserved.text')}}, <a href="https://ultimatesolutionsportal.com" target="_blank">{{$t('UltimateSolutions.text')}}</a> &copy; {{new Date().getFullYear()}}  ({{versionNumber}})</div>


      <div v-if="show" class="col-md-3"><div class="d-flex justify-content-end"><router-link :to="{name: 'SchoolTerms' , state: {schoolName:'branches_demo' , params : 'branches_demo'}}">{{$t('Terms.text')}}</router-link></div></div>

    </div>
  </div>
</template>


<script>
export default {
name: "app-footer",
  data () {
    return {
      versionNumber: process.env.VUE_APP_VERSION_NUMBER,
      publicPath: process.env.BASE_URL,
      schoolName : localStorage.getItem('schoolName'),
      show:false
    }
  }, methods: {
  },
  mounted() {
    setTimeout(() => {
      this.show = true
    }, 1000)
  }
}
</script>

<style scoped>
.app-footer {
  text-align: initial;
  position: absolute;
  bottom: 5px;
  left: 10px;
  right: 10px;
}
</style>