<template>
  <div class="form-group row error" v-if="formValidation && isDirty && langs.$each.$response.$errors[index][inputToCheck].length">
    <label class="col-sm-3 col-form-label" ></label>
    <div class="col-sm-9 d-flex justify-content-between" v-for="error in langs.$each.$response.$errors[index][inputToCheck]" :key="error" style="color:red;">
      <span v-if="error.$validator == 'required'">{{$t('requiredMsg.text')}}</span>
      <span v-if="error.$validator == 'minLength'">{{$t('minLengthMsg.text')}}</span>
      <span v-if="error.$validator == 'maxLength'">{{$t('maxLengthMsg.text')}}</span>
    </div>
  </div>
</template>
<script>

export default {
  props: ['formValidation', 'inputToCheck', 'isDirty', 'index', 'langs'],
}
</script>