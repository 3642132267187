<template>
    <div class="mb-4">
        <div class="card" v-if="isAdmin()">
            <div class="card-body">
                <div class="pb-5" v-if="isLoading">
                    <base-loader :start-loading="isLoading"></base-loader>
                </div>
                
                <div class="row" v-if="!isLoading" >
                    <div class="col-md-6 col-sm-12">
                        
                        <div class="ul-widget__body">
                          <div class="card-header text-initial">
                            <h4><i class="fas fa-receipt"></i> {{$t('Invoices.text')}}</h4>
                        </div>
                        <div class="table-responsive">
                          <table v-if="Object.keys(dashboardData).length" class="table border table-bordered">
                            <thead>
                            <th>#</th>
                            <th>{{$t('title.text')}}</th>
                            <th>{{$t('Status.text')}}</th>
                            <th>{{$t('Date.text')}}</th>
                            <th>{{$t('dueDate.text')}}</th>
                            <th>Due {{$t('Amount.text')}}</th>
                            </thead>
                            <tbody>
                            <tr v-for="(invoice, index) in invoices" :key="index">
                              <td>{{index + 2}}</td>
                              <td>{{invoice.paymentDescription}}</td>
                              <td class="align-middle">
                                <span v-if="invoice.paymentStatus == '0'" class="badge badge-label badge-danger text-white">{{$t('unpaid.text')}}</span>
                                <span v-if="invoice.paymentStatus == '1'" class="badge badge-label badge-success text-white">{{$t('paid.text')}}</span>
                                <span v-if="invoice.paymentStatus == '2'" class="badge badge-label badge-warning text-white">{{$t('ppaid.text')}}</span>
                              </td>
                              <td>{{invoice.paymentDate}}</td>
                              <td>{{invoice.dueDate}}</td>
                              <td>{{invoice.paymentAmount}} {{dashboardData.defCuurencySymbol}}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="text-center" style="max-width: 450px">
                            <vue3-chart-js v-bind="{ ...dataChart }"/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import BaseLoader from "@/components/Shared/BaseLoader";
import {mapState} from "vuex";

export default {
  name: "App",
  components: {
    BaseLoader,
    Vue3ChartJs,
  },
  data() {
    return {
      invoices : [],
      isLoading: true,
      dataChart: {
        type: "doughnut",
        options: {
          //min: 0,
          //max: 100,
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
        },
        data: {
          labels: [this.$t('paidInvoce.text'), this.$t('subpaidInvoce.text'), this.$t('unpaidInvoce.text')],
        },
      }
    }
  },
  computed:{
    ...mapState('dashboard', {
      dashboardData : 'dashboardData',
    }),
  },
  created() {

  },
  methods: {
    setData() {
      this.dataChart.data.datasets =  [{
        label: 'My First Dataset',
        data: [
          this.dashboardData.stats.paidInvoceCount + 1,
          this.dashboardData.stats.subpaidInvoceCount + 1,
          this.dashboardData.stats.unpaidInvoceCount + 1
        ],
        backgroundColor: [
          'rgb(21,196,23)',
          'rgb(255, 205, 86)',
          'rgb(255, 99, 132)'
        ],
        hoverOffset: 4
      }]
      this.invoices = this.dashboardData.invoices;
      this.isLoading = false;
    }
  },
  watch: {
    dashboardData(newD) {
      if(Object.keys(newD).length) {
         this.setData();
      }

    }
  },
};
</script>