<template>
  <div class="app-all-wrap" data-theme-color="gradient-purple-indigo">

    <div v-if="!isLogin && !isNotFound && !isInvalid && !isExpired && !InvalidUrl && !isForgetPassword && !contactUs">
      
        <div class="loading-screen" :style="{backgroundSize:'cover' ,backgroundImage: 'url(' + `${publicPath}assets/images/news_online-education-anyone.jpg `+ ')' }">
            <div class="inner">
                <div class="loader-bubble loader-bubble-primary"></div>
            </div>
        </div>

      <div v-if="showDashboard" class="app-admin-wrap layout-sidebar-vertical sidebar-full">
        <Sidebar></Sidebar>

        <div class="switch-overlay"></div>
        <div class="main-content-wrap mobile-menu-content m-0">
          <AppHeader></AppHeader>

            <div id="excelLoading">
                <div class="inner">
                    <h4>{{ $t('ExportExcel.text') }}</h4>
                    <div class="pb-4"><div class="loader-bubble loader-bubble-primary"></div></div>
                </div>
            </div>

            <div id="pdfLoading">
                <div class="inner">
                    <h4>{{ $t('ExportPDF.text') }}</h4>
                    <div class="pb-4"><div class="loader-bubble loader-bubble-primary"></div></div>
                </div>
            </div>

          <div class="mb-5 pb-5"><router-view/></div>
          <AppFooter></AppFooter>
        </div>
      </div>

      <div  class="search-ui">
        <div class="search-header">
          <img :src="`${publicPath}assets/images/logo.png`" alt="" class="logo">
          <button class="search-close btn btn-icon bg-transparent float-right mt-2">
            <i class="i-Close-Window text-22 text-muted"></i>
          </button>
        </div>
        <input type="text" placeholder="Type here" class="search-input" autofocus>
        <div class="search-title">
          <span id="h1" class="text-muted">Search results</span>
        </div>
        <div class="search-results list-horizontal">
          <div class="list-item col-md-12 p-0">
            <div class="card o-hidden flex-row mb-4 d-flex">
              <div class="list-thumb d-flex">
                <!-- TUMBNAIL -->
                <img :src="`${publicPath}assets/images/products/headphone-1.jpg`" alt="">
              </div>
              <div class="flex-grow-1 pl-2 d-flex">
                <div class="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
                  <!-- OTHER DATA -->
                  <a href="" class="w-40 w-sm-100">
                    <div class="item-title">Headphone 1</div>
                  </a>
                  <p class="m-0 text-muted text-small w-15 w-sm-100">Gadget</p>
                  <p class="m-0 text-muted text-small w-15 w-sm-100">$300
                    <del class="text-secondary">$400</del>
                  </p>
                  <p class="m-0 text-muted text-small w-15 w-sm-100 d-none d-lg-block item-badges">
                    <span class="badge badge-label badge-danger text-white">Sale</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="list-item col-md-12 p-0">
            <div class="card o-hidden flex-row mb-4 d-flex">
              <div class="list-thumb d-flex">
                <!-- TUMBNAIL -->
                <img :src="`${publicPath}assets/images/products/headphone-2.jpg`" alt="">
              </div>
              <div class="flex-grow-1 pl-2 d-flex">
                <div class="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
                  <!-- OTHER DATA -->
                  <a href="" class="w-40 w-sm-100">
                    <div class="item-title">Headphone 1</div>
                  </a>
                  <p class="m-0 text-muted text-small w-15 w-sm-100">Gadget</p>
                  <p class="m-0 text-muted text-small w-15 w-sm-100">$300
                    <del class="text-secondary">$400</del>
                  </p>
                  <p class="m-0 text-muted text-small w-15 w-sm-100 d-none d-lg-block item-badges">
                    <span class="badge badge-primary">New</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="list-item col-md-12 p-0">
            <div class="card o-hidden flex-row mb-4 d-flex">
              <div class="list-thumb d-flex">
                <!-- TUMBNAIL -->
                <img :src="`${publicPath}assets/images/products/headphone-3.jpg`" alt="">
              </div>
              <div class="flex-grow-1 pl-2 d-flex">
                <div class="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
                  <!-- OTHER DATA -->
                  <a href="" class="w-40 w-sm-100">
                    <div class="item-title">Headphone 1</div>
                  </a>
                  <p class="m-0 text-muted text-small w-15 w-sm-100">Gadget</p>
                  <p class="m-0 text-muted text-small w-15 w-sm-100">$300
                    <del class="text-secondary">$400</del>
                  </p>
                  <p class="m-0 text-muted text-small w-15 w-sm-100 d-none d-lg-block item-badges">
                    <span class="badge badge-primary">New</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="list-item col-md-12 p-0">
            <div class="card o-hidden flex-row mb-4 d-flex">
              <div class="list-thumb d-flex">
                <!-- TUMBNAIL -->
                <img :src="`${publicPath}assets/images/products/headphone-4.jpg`" alt="">
              </div>
              <div class="flex-grow-1 pl-2 d-flex">
                <div class="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
                  <!-- OTHER DATA -->
                  <a href="" class="w-40 w-sm-100">
                    <div class="item-title">Headphone 1</div>
                  </a>
                  <p class="m-0 text-muted text-small w-15 w-sm-100">Gadget</p>
                  <p class="m-0 text-muted text-small w-15 w-sm-100">$300
                    <del class="text-secondary">$400</del>
                  </p>
                  <p class="m-0 text-muted text-small w-15 w-sm-100 d-none d-lg-block item-badges">
                    <span class="badge badge-primary">New</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- PAGINATION CONTROL -->
        <div class="col-md-12 mt-5 text-center">
          <nav aria-label="Page navigation example">
            <ul class="pagination d-inline-flex">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="{{ $t('previous.text') }}">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">{{ $t('previous.text') }}</span>
                </a>
              </li>
              <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">{{ $t('next.text') }}</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Customizer></Customizer>
    </div>

    <div v-if="isLogin || isInvalid || isExpired || InvalidUrl || isForgetPassword || contactUs" class="big-bg auth-layout-wrap" :style="{backgroundImage: 'url(' + `${publicPath}assets/images/news_online-education-anyone.jpg `+ ')' }">
      <div class="auth-content">
        <div class="card o-hidden">
          <router-view></router-view>
        </div>
      </div>
    </div>

    <div v-if="isNotFound " class="not-found-wrap text-center">
      <router-view></router-view>
    </div>


  </div>
</template>
<script>
  import Customizer from './components/common/customizer.vue'
  import Sidebar from './components/common/sidebar.vue'
  import AppFooter from './components/common/app-footer.vue'
  import AppHeader from './components/common/app-header.vue'
  import {mapState} from "vuex";
  export default {
    name: 'App',
    components: {
      Customizer,
      Sidebar,
      AppHeader,
      AppFooter
    },
    data () {
      return {
        publicPath: process.env.BASE_URL,
        showDashboard:false
      }
    },
    created() {
      if(localStorage.getItem('token')) {
        this.$store.dispatch('tryAutoLogin');
        // this.$store.dispatch('profile');
        // this.$store.dispatch('dashboard');
      }
      this.showDashboard = true;
    },
    methods: {
    },
    mounted() {
      // if(this.$dhashboardData) {
        // this.$i18n.locale = this.$dashboardData.languageUniversal;
        // var dir = ''
        // if(this.$dhashboardData.isRTL == 1) {
        //   dir = 'rtl'
        // }else {
        //   dir = 'ltr'
        // }
        // let htmlEl=document.querySelector("html");
        //
        // htmlEl.setAttribute('dir',dir);
        // htmlEl.setAttribute('lang',this.$dhashboardData.languageUniversal);
        //
        // if(!this.helpTitle) {
        //   console.log("heloooo");
        //   const css = `
        //   [data-hint] {display:none}
        //   `;
        //   this.styleTag = document.createElement('style');
        //   this.styleTag.appendChild(document.createTextNode(css));
        //   document.head.appendChild(this.styleTag);
        // }

        // setTimeout(function () {
        //     if (document.getElementsByClassName('loading-screen')[0]) {
        //         document.getElementsByClassName('loading-screen')[0].style.opacity = 0;
        //         setTimeout(function () {
        //             document.getElementsByClassName('loading-screen')[0].style.display = 'none';
        //         }, 300);
        //     }
        // }, 3000);
      // }
    },
    computed: {
      isLogin() {
        return this.$route.name === 'Login'
      },
      isNotFound() {
        return this.$route.name === 'notFound'
      },
      isDashboard() {
        return this.$route.name === 'Dashboard'
      },
      isInvalid() {
        return this.$route.name === 'InvalidLicense'
      },
      isExpired() {
        return this.$route.name === 'ExpiredLicense'
      },
      InvalidUrl() {
        return this.$route.name === 'InvalidUrl'
      },
      isForgetPassword() {
        return this.$route.name === 'ForgetPassword'
      },
      helpTitle() {
        return this.$dashboardData.activateHelp  == 1;
      },
      contactUs() {
        return this.$route.name === 'ContactUs'
      },
      title(){
        return this.$t(this.$route.meta.title+'.text') + " | " + localStorage.getItem('schoolName');
      },

      ...mapState('dashboard', {
        dashboardData : 'dashboardData',

      }),
    },
    beforeCreate() {
      this.$store.dispatch('dashboard');
    },
    watch: {
      dashboardData(newD,oldD) {
        if(!Object.keys(newD).length) {
          if (document.getElementsByClassName('loading-screen')[0]) {
            document.getElementsByClassName('loading-screen')[0].style.opacity = 0;
          }
        }else {
          if (document.getElementsByClassName('loading-screen')[0]) {
            document.getElementsByClassName('loading-screen')[0].style.display = 'none';
          }
        }
        if (oldD.languageUniversal != newD.languageUniversal) {
          this.$i18n.locale = newD.languageUniversal;
        }
      },
      '$i18n.locale': function() {
        document.title = this.title   ;
      },
      $route (){
        document.title = this.title    ;
      },


    },

  }
</script>
<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  #nav {
    padding: 30px;
  }
  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }
  #nav a.router-link-exact-active {
    color: #42b983;
  }
  .big-bg {
    position: relative;
  }
  .big-bg::before {
    content: '';
    background: rgba(0,0,0,.4);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .loading-screen {
      position: fixed;
      background: #fff;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      display: flex;
      z-index: 999999999999999999;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -ms-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
  }
  .loading-screen .inner {margin: auto;}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
