<template>
  <div class="breadcrumb">
    <ul>
<!--      <li><a href="" class="text-muted">{{$t('dashboard.text')}}</a></li>-->
    </ul>
  </div>
</template>

<script>

export default {
name: "breadcrumb"
}
</script>
