<template>
  <div  class="col">

    <div class="card card-profile-1 mb-4">
        <div class="card-header text-initial">
                <h4><i class="fas fa-table"></i> {{$t('classSch.text')}}</h4>
            </div>
        <div class="card-body text-center">
        <div class="ul-widget__body">
          <div class="ul-widget1">
            <table v-if="!isParent() && dashboardData.schedule" class="table table-bordered">
              <thead>
              <th>
                <span v-if="dashboardData.enableSections != '1'">{{ $t('className.text') }}</span>
                <span v-if="dashboardData.enableSections == '1'">{{ $t('section.text') }}</span>
              </th>
              <th>{{ $t('Subject.text') }}</th>
              <th v-if="dashboardData.typeOfSchedule=='session'">{{ $t('session.text') }}</th>
              <th v-if="dashboardData.typeOfSchedule=='session'">{{ $t('time.text') }}</th>
              </thead>
              <tbody>
              <tr v-for="(schedule, index) in dashboardData.schedule" :key="index">
                <td>
                  <span v-if="dashboardData.enableSections != '1'">
                    <span v-if="isStudent()"> {{ schedule.class }}</span>
                    <span v-if="!isStudent()"> {{ schedule.class.className }}</span>
                  </span>
                  <span v-if="dashboardData.enableSections == '1'">
                    <span v-if="isStudent()"> {{ schedule.section }}</span>
                    <span v-if="!isStudent()"> {{ schedule.sectionTitle }}</span>
                  </span>
                </td>
                <td>
                  <span v-if="isStudent()"> {{ schedule.subject }}</span>
                  <span v-if="!isStudent()"> {{ schedule.subject.subjectTitle }}</span>
                </td>
                <td v-if="dashboardData.typeOfSchedule=='session'">
                  {{ schedule.sessionNumber }}
                </td>
                <td v-if="dashboardData.typeOfSchedule=='session'">
                  {{ schedule.startTime }} -> {{ schedule.endTime }}
                </td>
              </tr>
              </tbody>
            </table>

            <div v-if="isParent() && dashboardData.scheduleStd">
              <div v-for="(student,index) in dashboardData.scheduleStd" :key="index">
                <h5>{{student.fullName}}</h5>
                <table class="table table-bordered table-hover">
                  <tbody v-if="dashboardData.enableSections != '1'">
                  <tr>
                    <th>
                      <span v-if="dashboardData.enableSections != '1'">{{ $t('className.text') }}</span>
                      <span v-if="dashboardData.enableSections == '1'">{{ $t('section.text') }}</span>
                    </th>
                    <th>{{ $t('Subject.text') }}</th>
                    <th v-if="dashboardData.typeOfSchedule !== 'session'">{{ $t('time.text') }}</th>
                    <th v-if="dashboardData.typeOfSchedule == 'session'">{{ $t('session.text') }}</th>
                  </tr>
                  <tr v-for="(value, index2) in dashboardData.schedule[index]" :key="index2">
                    <td>
                      <span v-if="dashboardData.enableSections != '1'">{{ value.class }}</span>
                      <span v-if="dashboardData.enableSections == '1'">{{ value.section }}</span>
                    </td>
                    <td>{{ value.subject }}</td>
                    <td v-if="dashboardData.typeOfSchedule !== 'session'">{{ value.startTime }} ->
                      {{ value.endTime }}
                    </td>
                    <td v-if="dashboardData.typeOfSchedule == 'session'">{{ value.sessionNumber }}</td>
                  </tr>
                  </tbody>
                  <tbody v-if="dashboardData.enableSections == '1'">
                  <tr>
                    <th>
                      <span v-if="dashboardData.enableSections != '1'">{{ $t('className.text') }}</span>
                      <span v-if="dashboardData.enableSections == '1'">{{ $t('section.text') }}</span>
                    </th>
                    <th>{{ $t('Subject.text') }}</th>
                    <th v-if="dashboardData.typeOfSchedule !== 'session'">{{ $t('time.text') }}</th>
                    <th v-if="dashboardData.typeOfSchedule == 'session'">{{ $t('session.text') }}</th>
                  </tr>
                  <tr v-for="(value, index2) in dashboardData.schedule[index]" :key="index2">
                    <td>
                      <span v-if="dashboardData.enableSections != '1'">{{ value.class }}</span>
                      <span v-if="dashboardData.enableSections == '1'">{{ value.section }} - {{ value.section }}</span>
                    </td>
                    <td>{{ value.subject }}</td>
                    <td v-if="dashboardData.typeOfSchedule !== 'session'">{{ value.startTime }} -> {{ value.endTime }}</td>
                    <td v-if="dashboardData.typeOfSchedule == 'session'">{{ value.sessionNumber }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {mapState} from 'vuex';

export default {
  name: "grades",
  data() {
    return {}
  },
  computed: {
    ...mapState('dashboard', {
      dashboardData : 'dashboardData'
    }),
  },
}
</script>

<style scoped>

</style>