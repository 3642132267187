import { createRouter, createWebHistory } from 'vue-router'
import Dashboard                          from '../components/dashboard/dashboard.vue'
import store                              from '../store'
import Login                              from "../views/Login"
import ContactUs                          from "../views/ContactUs"
import ForgetPassword                     from "../views/ForgetPassword"
import axios                              from "../plugins/axios";

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/:schoolName',
        name: 'Dashboard',
        meta:{title:'dashboard'},
        // beforeEnter() {location.href = process.env.VUE_APP_REGISTER},
        component: Dashboard,
        children: [
            {
                path: 'academic-years',
                name: 'AcademicYears',
                meta:{link:'academic-years',title:'listAcademicyear',roles:['admin','HOD'],adminPerm : 'academicyears'},
                component: () => import ('../components/AcademicYears/list'),
                children: [
                    {
                        path: 'create',
                        name: 'AcademicYearCreate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'],adminPerm : 'academicyears'},
                        component: () => import ('../components/AcademicYears/create')
                    }, {
                        path: 'update',
                        name: 'AcademicYearUpdate',
                        meta:{ roles:['admin','teacher','student','parent','manager','ministerial'],adminPerm : 'academicyears'},
                        component: () => import ('../components/AcademicYears/update')
                    }
                ]
            },
            {
                path: 'events',
                name: 'Event',
                meta:{link:'events',title:'events',roles:['admin','teacher','student','parent','manager','HOD'] , activatedModules :'eventsAct',adminPerm : 'events'},
                component: () => import ('../components/event/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'EventCreate',
                        meta:{roles:['admin'], activatedModules :'eventsAct',adminPerm : 'events'},
                        component: () => import ('../components/event/create.vue')
                    }, {
                        path: 'update',
                        name: 'EventUpdate',
                        meta:{ roles:['admin'],activatedModules :'eventsAct',adminPerm : 'events'},
                        component: () => import ('../components/event/update.vue')
                    }, {
                        path: 'show',
                        name: 'EventShow',
                        meta:{activatedModules :'eventsAct',adminPerm : 'events'},
                        component: () => import ('../components/event/show.vue')
                    }, {
                        path: 'views',
                        name: 'EventShowViews',
                        meta:{roles:['admin'], activatedModules :'eventsAct',adminPerm : 'events'},
                        component: () => import ('../components/Shared/showViews')
                    }
                ]
            },
            {
                path: 'contact-us-messages',
                name: 'ContactUsAdmin',
                meta:{link:'contact-us-messages',title:'contactUs',roles:['admin'],adminPerm : 'contactUs'},
                component: () => import ('../components/ContactUs/list.vue'),
                children: [
                    {
                        path: 'show',
                        name: 'ContactUsShow',
                        meta:{roles:['admin'], adminPerm : 'contactUs'},
                        component: () => import ('../components/ContactUs/show.vue')
                    },
                ]
            },
            {
                path: 'news',
                name: 'News',
                meta:{link:'news-board',title:'newsboard',roles:['admin','teacher','student','parent','manager','HOD'], activatedModules :'newsboardAct',adminPerm : 'newsboard'},
                component: () => import ('../components/newsBoard/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'NewsCreate',
                        meta:{roles:['admin'], activatedModules :'newsboardAct',adminPerm : 'newsboard'},
                        component: () => import ('../components/newsBoard/create.vue')
                    }, {
                        path: 'update',
                        name: 'NewsUpdate',
                        meta:{ roles:['admin'],activatedModules :'newsboardAct',adminPerm : 'newsboard'},
                        component:() => import ('../components/newsBoard/update.vue')
                    }, {
                        path: 'show',
                        name: 'NewsShow',
                        meta:{ activatedModules :'newsboardAct',adminPerm : 'newsboard'},
                        component:() => import ('../components/newsBoard/show.vue')
                    }, {
                        path: 'views',
                        name: 'NewsShowViews',
                        meta:{roles:['admin'], activatedModules :'newsboardAct',adminPerm : 'newsboard'},
                        component: () => import ('../components/Shared/showViews')
                    }
                ]
            },
            {
                path: 'media',
                name: 'Media',


                component: () => import ('../components/media/list.vue'),
                meta:{link:'media',title:'mediaCenter',roles:['admin','teacher','student','parent','manager','HOD'], activatedModules :'mediaAct',adminPerm : 'mediaCenter'},
                children: [
                    {
                        path: 'album-create',
                        name: 'AlbumCreate',
                        meta:{roles:['admin','teacher','manager','HOD'], activatedModules :'mediaAct',adminPerm : 'mediaCenter'},
                        component: () => import ('../components/media/album-create.vue')
                    }, {
                        path: 'media-create',
                        name: 'MediaCreate',
                        meta:{roles:['admin','teacher','manager','HOD'], activatedModules :'mediaAct',adminPerm : 'mediaCenter'},
                        component: () => import ('../components/media/media-create.vue')
                    }, {
                        path: 'album-update',
                        name: 'AlbumUpdate',
                        meta:{ roles:['admin','teacher','manager','HOD'],activatedModules :'mediaAct',adminPerm : 'mediaCenter'},
                        component: () => import ('../components/media/album-update.vue')
                    }, {
                        path: 'media-update',
                        name: 'MediaUpdate',
                        meta:{ roles:['admin','teacher','manager','HOD'],activatedModules :'mediaAct',adminPerm : 'mediaCenter'},
                        component: () => import ('../components/media/media-update.vue')
                    }, {
                        path: 'album-show',
                        name: 'AlbumShow',
                        meta:{roles:['admin','teacher','manager','HOD'], activatedModules :'mediaAct',adminPerm : 'mediaCenter'},
                        component: () => import ('../components/media/album-show.vue')
                    }
                ]
            },
            {
                path: 'polls',
                name: 'Poll',
                meta:{link:'polls',title:'listPolls',roles:['admin','HOD'], activatedModules :'pollsAct',adminPerm : 'Polls'},
                component: () => import ('../components/poll/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'PollCreate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'], activatedModules :'pollsAct',adminPerm : 'Polls'},
                        component: () => import ('../components/poll/create.vue')
                    }, {
                        path: 'update',
                        name: 'PollUpdate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'], activatedModules :'pollsAct',adminPerm : 'Polls'},
                        component: () => import ('../components/poll/update.vue')
                    }, {
                        path: 'show',
                        name: 'PollShowViews',
                        meta:{roles:['admin'], activatedModules :'pollsAct',adminPerm : 'Polls'},
                        component: () => import ('../components/poll/seen.vue')
                    }
                ]
            },
            {
                path: 'admission',
                name: 'Admissions',
                meta:{link:'admissions',title:'admission',roles:['admin'],adminPerm : 'admission'},
                component: () => import ('../components/Admissions/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'AdmissionCreate',
                        meta:{roles:['admin','student','parent','manager','HOD','ministerial'],adminPerm : 'admission'},
                        component: () => import ('../components/Admissions/create.vue')
                    }, {
                        path: 'update',
                        name: 'AdmissionUpdate',
                        meta:{roles:['admin','student','parent','manager','HOD','ministerial'], adminPerm : 'admission'},
                        component: () => import ('../components/Admissions/update.vue')
                    }, {
                        path: 'interview',
                        name: 'AdmissionInterview',
                        meta:{roles:['admin','student','parent','manager','HOD','ministerial'], adminPerm : 'admission'},
                        component: () => import ('../components/Admissions/interview.vue')
                    }
                ]
            },
            {
                path: 'principals',
                name: 'Principals',
                meta:{link:'principals',title:'gradeManager',roles:['admin','HOD'],adminPerm : 'manager'},
                component: () => import ('../components/Principals/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'PrincipalCreate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'],adminPerm : 'manager'},
                        component: () => import ('../components/Principals/create.vue')
                    }, {
                        path: 'update',
                        name: 'PrincipalUpdate',
                        meta:{roles:['admin'], adminPerm : 'manager'},
                        component: () => import ('../components/Principals/update.vue')
                    }, {
                        path: 'show',
                        name: 'PrincipalShow',
                        meta:{ adminPerm : 'manager'},
                        component: () => import ('../components/Principals/show.vue')
                    },
                    {
                        path: 'import',
                        name: 'PrincipalImport',
                        meta:{roles:['admin'], adminPerm : 'manager'},
                        component: () => import ( /* webpackChunkName: "principals" */ '../components/Principals/import.vue')
                    }
                ]
            },
            {
                path: 'coordinators',
                name: 'HeadOfDepartment',
                meta:{link:'headDepartment',title:'HOD',roles:['admin','HOD'],adminPerm : 'HOD'},
                component: () => import ('../components/headOfDepartment/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'HeadOfDepartmentCreate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'],adminPerm : 'HOD'},
                        component: () => import ('../components/headOfDepartment/create.vue')
                    }, {
                        path: 'update',
                        name: 'HeadOfDepartmentUpdate',
                        meta:{ roles:['admin'],adminPerm : 'HOD'},
                        component: () => import ('../components/headOfDepartment/update.vue')
                    }, {
                        path: 'show',
                        name: 'HeadOfDepartmentShow',
                        meta:{ adminPerm : 'HOD'},
                        component: () => import ('../components/headOfDepartment/show.vue')
                    },  {
                        path: 'import',
                        name: 'HeadOfDepartmentImport',
                        meta:{roles:['admin'],adminPerm : 'HOD'},
                        component: () => import ('../components/headOfDepartment/import.vue')
                    }
                ]
            },
            {
                path: 'ministerial-supervisor',
                name: 'MinisterialSupervisor',
                meta:{link:'ministerial-supervisors',title:'ministerialSupervisor',roles:['admin'], activatedModules :'MinisterialSupervisorAct',adminPerm : 'MinisterialSupervisor'},
                component: () => import ('../components/MinisterialSupervisor/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'MinisterialSupervisorCreate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'], activatedModules :'MinisterialSupervisorAct',adminPerm : 'MinisterialSupervisor'},
                        component: () => import ('../components/MinisterialSupervisor/create.vue')
                    }, {
                        path: 'update',
                        name: 'MinisterialSupervisorUpdate',
                        meta:{ activatedModules :'MinisterialSupervisorAct',adminPerm : 'MinisterialSupervisor'},
                        component: () => import ('../components/MinisterialSupervisor/update.vue')
                    }, {
                        path: 'show',
                        name: 'MinisterialSupervisorShow',
                        meta:{ activatedModules :'MinisterialSupervisorAct',adminPerm : 'MinisterialSupervisor'},
                        component: () => import ('../components/MinisterialSupervisor/show.vue')
                    },  {
                        path: 'import',
                        name: 'MinisterialSupervisorImport',
                        meta:{ activatedModules :'MinisterialSupervisorAct',adminPerm : 'MinisterialSupervisor'},
                        component: () => import ('../components/MinisterialSupervisor/import.vue')
                    }
                ]
            },
            {
                path: 'ministerial-evalautions',
                name: 'MinisterialSupervisorEvaluation',
                meta:{link:'ministerial-evalautions',title:'ministerialSupervisorEvaluation',roles:['admin','teacher','ministerial'],adminPerm : 'ministerialSupervisorEvaluation'},
                component: () => import ('../components/MinisterialSupervisorEvaluation/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'MinisterialSupervisorEvaluationCreate',
                        meta:{roles:['ministerial'],adminPerm : 'ministerialSupervisorEvaluation'},
                        component: () => import ('../components/MinisterialSupervisorEvaluation/create.vue')
                    }, {
                        path: 'update',
                        name: 'MinisterialSupervisorEvaluationUpdate',
                        meta:{ roles:['ministerial'],adminPerm : 'ministerialSupervisorEvaluation'},
                        component: () => import ('../components/MinisterialSupervisorEvaluation/update.vue')
                    }
                ]
            },
            {
                path: 'teacher-preparing',
                name: 'TeacherPreparing',
                meta:{link:'teacherPreparing',title:'TeacherPreparing',roles:['admin','teacher','manager','ministerial'], activatedModules :'teacherPreparingAct',adminPerm : 'teacherPreparing'},
                component: () => import ('../components/teacherPreparing/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'TeacherPreparingCreate',
                        meta:{roles:['admin','teacher'], activatedModules :'teacherPreparingAct',adminPerm : 'teacherPreparing'},
                        component: () => import ('../components/teacherPreparing/create.vue')
                    }, {
                        path: 'update',
                        name: 'TeacherPreparingUpdate',
                        meta:{ activatedModules :'teacherPreparingAct',adminPerm : 'teacherPreparing'},
                        component: () => import ('../components/teacherPreparing/update.vue')
                    }, {
                        path: 'show',
                        name: 'TeacherPreparingShow',
                        meta:{ activatedModules :'teacherPreparingAct',adminPerm : 'teacherPreparing'},
                        component: () => import ('../components/teacherPreparing/show.vue')
                    }
                ]
            },
            {
                path: 'educational-levels',
                name: 'EducationalLevels',
                meta:{link:'educational-levels',title:'schoolGrades',roles:['admin'],adminPerm : 'schoolGrades'},
                component: () => import ('../components/EducationalLevels/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'EducationalLevelCreate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'],adminPerm : 'schoolGrades'},
                        component: () => import ('../components/EducationalLevels/create.vue')
                    }, {
                        path: 'update',
                        name: 'EducationalLevelUpdate',
                        meta:{roles:['admin'], adminPerm : 'schoolGrades'},
                        component: () => import ('../components/EducationalLevels/update.vue')
                    },
                    {
                        path: 'import',
                        name: 'EducationalLevelsImport',
                        meta:{roles:['admin'],adminPerm : 'schoolGrades'},
                        component: () => import ('../components/EducationalLevels/import.vue')
                    }

                ]
            },
            {
                path: 'extra-fields',
                name: 'ExtraFields',
                meta:{link:'extra-fields',title:'extraFields',roles:['admin','HOD'],adminPerm : 'extraFields'},
                component: () => import ('../components/ExtraFields/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'ExtraFieldCreate',
                        meta:{ adminPerm : 'extraFields'},
                        component: () => import ('../components/ExtraFields/create.vue')
                    }, {
                        path: 'update',
                        name: 'ExtraFieldUpdate',
                        meta:{ adminPerm : 'extraFields'},
                        component: () => import ('../components/ExtraFields/update.vue')
                    }, {
                        path: 'import',
                        name: 'ExtraFieldImport',
                        meta:{ adminPerm : 'extraFields'},
                        component: () => import ('../components/ExtraFields/import.vue')
                    }
                ]
            },
            {
                path: 'attachments',
                name: 'Attatchment',
                meta:{link:'attachments',title:'AttatchmentFiles',roles:['admin','HOD'],adminPerm : 'AttatchmentFiles'},
                component: () => import ('../components/Attatchment/list.vue'),
                children: [{
                    path: 'create',
                    name: 'AttatchmentCreate',
                    meta:{ adminPerm : 'AttatchmentFiles'},
                    component: () => import ('../components/Attatchment/create.vue')
                }, {
                    path: 'update',
                    name: 'AttatchmentUpdate',
                    meta:{ adminPerm : 'AttatchmentFiles'},
                    component: () => import ('../components/Attatchment/update.vue')
                }
                ]
            },
            {
                path: 'student-identifiers',
                name: 'Identifier',
                meta:{link:'identifiers',title:'identifier',roles:['admin','HOD'],adminPerm : 'identifier'},
                component: () => import ('../components/Identifier/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'IdentifierCreate',
                        meta:{ adminPerm : 'identifier'},
                        component: () => import ('../components/Identifier/create.vue')
                    }, {
                        path: 'update',
                        name: 'IdentifierUpdate',
                        meta:{ adminPerm : 'identifier'},
                        component: () => import ('../components/Identifier/update.vue')
                    }, {
                        path: 'show',
                        name: 'IdentifierSeen',
                        meta:{ adminPerm : 'identifier'},
                        component: () => import ('../components/Identifier/seen.vue')
                    }
                ]
            },
            {
                path: 'classworks-classification',
                name: 'YearWorks',
                meta:{link:'classworks',title:'yearWorksStandards',roles:['admin','HOD'],adminPerm : 'yearWorks'},
                component: () => import ('../components/YearWorks/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'YearWorkCreate',
                        meta:{ adminPerm : 'yearWorks'},
                        component: () => import ('../components/YearWorks/create.vue')
                    }, {
                        path: 'update',
                        name: 'YearWorkUpdate',
                        meta:{ adminPerm : 'yearWorks'},
                        component: () => import ('../components/YearWorks/update.vue')
                    },
                    {
                        path: 'import',
                        name: 'YearWorkImport',
                        meta:{roles:['admin'],adminPerm : 'yearWorks'},
                        component: () => import ('../components/YearWorks/import.vue')
                    }
                ]
            },
            {
                path: 'evaluation-classwork-classification',
                name: 'KGYearWorks',
                meta:{link:'evaluation-classworks',title:'kgYearWorksStandards',roles:['admin','HOD','manager'], adminPerm : 'kgYearWorksStandards'},
                component: () => import ('../components/KGYearWorks/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'KGYearWorkCreate',
                        meta:{ adminPerm : 'kgYearWorksStandards'},
                        component: () => import ('../components/KGYearWorks/create.vue')
                    }, {
                        path: 'update',
                        name: 'KGYearWorkUpdate',
                        meta:{ adminPerm : 'kgYearWorksStandards'},
                        component: () => import ('../components/KGYearWorks/update.vue')
                    },
                    {
                        path: 'import',
                        name: 'KGYearWorkImport',
                        meta:{ adminPerm : 'kgYearWorksStandards'},
                        component: () => import ('../components/KGYearWorks/import.vue')
                    }
                ]
            },
            {
                path: 'buildings',
                name: 'Dormitories',
                meta:{link:'buildings',title:'Dormitories',roles:['admin','HOD'],adminPerm : 'Dormitories'},
                component: () => import ('../components/Dormitories/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'DormitorieCreate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'],adminPerm : 'Dormitories'},
                        component: () => import ('../components/Dormitories/create.vue')
                    }, {
                        path: 'update',
                        name: 'DormitorieUpdate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'], adminPerm : 'Dormitories'},
                        component: () => import ('../components/Dormitories/update.vue')
                    },
                    {
                        path: 'import',
                        name: 'DormitorieImport',
                        meta:{roles:['admin'],adminPerm : 'Dormitories'},
                        component: () => import ('../components/Dormitories/import.vue')
                    }
                ]
            },
            {
                path: 'hostels',
                name: 'Hostel',
                meta:{link:'hostels',title:'Hostel',roles:['admin','HOD'], activatedModules :'hostelAct',adminPerm : 'Hostel'},
                component: () => import ('../components/Hostel/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'HostelCreate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'], activatedModules :'hostelAct',adminPerm : 'Hostel'},
                        component: () => import ('../components/Hostel/create.vue')
                    }, {
                        path: 'update',
                        name: 'HostelUpdate',
                        meta:{ activatedModules :'hostelAct',adminPerm : 'Hostel'},
                        component: () => import ('../components/Hostel/update.vue')
                    },
                    {
                        path: 'subscribers',
                        name: 'HostelSubscribers',
                        meta:{ activatedModules :'hostelAct',adminPerm : 'Hostel'},
                        component: () => import ('../components/Hostel/subscribers.vue')
                    }
                ]
            },
            {
                path: 'teachers',
                name: 'Teachers',
                meta:{link:'teachers',title:'listTeachers',roles:['admin','manager','ministerial','HOD'],adminPerm : 'teachers'},
                component: () => import ('../components/Teachers/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'TeacherCreate',
                        meta:{roles:['admin','student','parent','manager','HOD'],adminPerm : 'teachers'},
                        component: () => import ('../components/Teachers/create.vue')
                    }, {
                        path: 'update',
                        name: 'TeacherUpdate',
                        meta:{roles:['admin','student','parent','manager','HOD'], adminPerm : 'teachers'},
                        component: () => import ('../components/Teachers/update.vue')
                    }, {
                        path: 'show',
                        name: 'TeacherShow',
                        meta:{ adminPerm : 'teachers'},
                        component: () => import ('../components/Teachers/show.vue')
                    }, {
                        path: 'headDepartment',
                        name: 'TeacherHeadDepartment',
                        meta:{roles:['admin','student','parent','manager','HOD'],adminPerm : 'teachers'},
                        component: () => import ('../components/Teachers/HeadDepartment.vue')
                    }, {
                        path: 'waitingApproval',
                        name: 'TeacherWaitingApproval',
                        meta:{roles:['admin','student','parent','manager','HOD'],adminPerm : 'teachers'},
                        component: () => import ('../components/Teachers/WaitingApproval.vue')
                    },
                    {
                        path: 'import',
                        name: 'TeacherImport',
                        meta:{roles:['admin'],adminPerm : 'teachers'},
                        component: () => import ('../components/Teachers/import.vue')
                    }
                ]
            },
            {
                path: 'school-terms',
                name: 'SchoolTerms',
                meta:{link:'terms',title:'schoolTerms',roles:['admin'],adminPerm : 'schoolTerms'},
                component: () => import ('../components/schoolTerms.vue'),
            },
            {
                path: 'transportation',
                name: 'Transports',
                meta:{link:'transports',title:'listTransport',roles:['admin','HOD'], activatedModules :'transportAct',adminPerm : 'Transport'},
                component: () => import ('../components/Transports/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'TransportCreate',
                        meta:{roles:['admin'], activatedModules :'transportAct',adminPerm : 'Transport'},
                        component: () => import ('../components/Transports/create.vue')
                    }, {
                        path: 'update',
                        name: 'TransportUpdate',
                        meta:{roles:['admin'], activatedModules :'transportAct',adminPerm : 'Transport'},
                        component: () => import ('../components/Transports/update.vue')
                    },
                    {
                        path: 'subscribers',
                        name: 'TransportSubscribers',
                        meta:{roles:['admin'], activatedModules :'transportAct',adminPerm : 'Transport'},
                        component: () => import ('../components/Transports/subscribers.vue')
                    }, {
                        path: 'import',
                        name: 'TransportImport',
                        meta:{roles:['admin'], activatedModules :'transportAct',adminPerm : 'Transport'},
                        component: () => import ('../components/Transports/import.vue')
                    }
                ]
            },
            {
                path: 'grading-scale',
                name: 'GradingScales',
                meta:{link:'grading-scales',title:'gradeLevels',roles:['admin','HOD'],adminPerm : 'gradeLevels'},
                component: () => import ('../components/GradingScales/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'GradingScaleCreate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'],adminPerm : 'gradeLevels'},
                        component: () => import ('../components/GradingScales/create.vue')
                    }, {
                        path: 'update',
                        name: 'GradingScaleUpdate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'], adminPerm : 'gradeLevels'},
                        component: () => import ('../components/GradingScales/update.vue')
                    }
                ]
            },
            {
                path: 'replace-teacher',
                name: 'ReplaceTeacher',
                meta:{link:'replace-teacher',title:'replaceTeacher',roles:['admin','manager'],adminPerm : 'replaceTeacher'},
                component: () => import ('../components/ReplaceTeacher/replace-teacher.vue')
            },
            {
                path: 'subjects',
                name: 'Subjects',
                meta:{link:'subjects',title:'Subjects',roles:['admin','manager'],adminPerm : 'Subjects'},
                component: () => import ('../components/Subjects/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'SubjectCreate',
                        meta:{roles:['admin'],adminPerm : 'Subjects'},
                        component: () => import ('../components/Subjects/create.vue')
                    }, {
                        path: 'update',
                        name: 'SubjectUpdate',
                        meta:{roles:['admin'], adminPerm : 'Subjects'},
                        component: () => import ('../components/Subjects/update.vue')
                    }, {
                        path: 'import',
                        name: 'SubjectImport',
                        meta:{roles:['admin'],adminPerm : 'Subjects'},
                        component: () => import ('../components/Subjects/import.vue')
                    }
                ]
            },
            {
                path: 'subject-classification',
                name: 'subjectClassification',
                meta:{link:'subject-classification',title:'subjectClassification',roles:['admin','manager'],adminPerm : 'subjectClassification'},
                component: () => import ('../components/subjectClassification/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'subjectClassificationCreate',
                        component: () => import ('../components/subjectClassification/create.vue')
                    }, {
                        path: 'update',
                        name: 'subjectClassificationUpdate',
                        component: () => import ('../components/subjectClassification/update.vue')
                    }
                ]
            },
            {
                path: 'grades',
                name: 'Grades',
                meta:{link:'grades',title:'classes',roles:['admin','manager','HOD'],adminPerm : 'classes'},
                component: () => import ('../components/Grades/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'GradeCreate',
                        meta:{roles:['admin','student','parent','manager','ministerial','HOD'],adminPerm : 'classes'},
                        component: () => import ('../components/Grades/create.vue')
                    }, {
                        path: 'update',
                        name: 'GradeUpdate',
                        meta:{roles:['admin'],adminPerm : 'gradeLevels'},
                        component: () => import ('../components/Grades/update.vue')
                    },
                    {
                        path: 'import',
                        name: 'GradeImport',
                        meta:{ adminPerm : 'gradeLevels'},
                        component: () => import ('../components/Grades/import.vue')
                    }
                ]
            },
            {
                path: 'classrooms',
                name: 'Sections',
                meta:{link:'classrooms',title:'listSections',roles:['admin','manager','HOD']},
                component: () => import ('../components/Sections/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'SectionCreate',
                        component: () => import ('../components/Sections/create.vue')
                    }, {
                        path: 'update',
                        name: 'SectionUpdate',
                        component: () => import ('../components/Sections/update.vue')
                    }, {
                        path: 'show',
                        name: 'SectionSeen',
                        component: () => import ('../components/Sections/seen.vue')
                    }, {
                        path: 'import',
                        name: 'SectionImport',
                        component: () => import ('../components/Sections/import.vue')
                    }
                ]
            },
            {
                path: 'students',
                name: 'Students',
                meta:{link:'students',title:'students',roles:['admin','teacher','parent','manager','HOD'],adminPerm : 'students'},
                component: () => import ('../components/Students/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'StudentCreate',
                        meta:{roles:['admin','student','parent','manager','HOD'],adminPerm : 'students'},
                        component: () => import ('../components/Students/create.vue')
                    }, {
                        path: 'update',
                        name: 'StudentUpdate',
                        meta:{roles:['admin','manager','HOD'], adminPerm : 'students'},
                        component: () => import ('../components/Students/update.vue')
                    }, {
                        path: 'show',
                        name: 'StudentShow',
                        meta:{ adminPerm : 'students'},
                        component: () => import ('../components/Students/show.vue')
                    }, {
                        path: 'show',
                        name: 'StudentSeen',
                        meta:{ adminPerm : 'students'},
                        component: () => import ('../components/Students/seen.vue')
                    }, {
                        path: 'transferred',
                        name: 'StudentTransferred',
                        meta:{roles:['admin','manager','HOD'],adminPerm : 'students'},
                        component: () => import ('../components/Students/transferStudents.vue')
                    },
                    {
                        path: 'medical-history',
                        name: 'StudentMedical',
                        meta:{roles:['admin','teacher','manager','HOD'],adminPerm : 'students'},
                        component: () => import ('../components/Students/medical.vue')
                    },
                    {
                        path: 'graduated',
                        name: 'StudentGraduated',
                        meta:{roles:['admin','manager','HOD'],adminPerm : 'students'},
                        component: () => import ('../components/Students/graduateStudents.vue')
                    },
                    {
                        path: 'attachment',
                        name: 'StudentAttachment',
                        meta:{roles:['admin','teacher','manager','HOD'],adminPerm : 'students'},
                        component: () => import ('../components/Students/attachment.vue')
                    },
                    {
                        path: 'student/attendance',
                        name: 'StudentAttendance',
                        meta:{roles:['admin','manager','HOD','parent'],adminPerm : 'students'},
                        component: () => import ('../components/Students/attendance.vue')
                    },
                    {
                        path: 'student-information',
                        name: 'StudentInfo',
                        meta:{roles:['admin','manager','HOD','parent'],adminPerm : 'students'},
                        component: () => import ('../components/Students/student-info.vue')
                    },
                    {
                        path: 'import',
                        name: 'StudentImport',
                        meta:{roles:['admin','manager','HOD'],adminPerm : 'students'},
                        component: () => import ('../components/Students/import.vue')
                    }
                ]
            },
            {
                path: 'non-enrolled-students',
                name: 'nonEnrolledStudents',
                meta:{link:'non-enrolled-students',title:'nonEnrolledStudents',roles:['admin'], activatedModules :'controlAct',adminPerm : 'nonEnrolledStudents'},
                component: () => import ('../components/NonEnrolledStudents/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'nonEnrolledStudentsCreate',
                        meta:{roles:['admin'], activatedModules :'controlAct',adminPerm : 'nonEnrolledStudents'},
                        component: () => import ('../components/NonEnrolledStudents/create.vue')
                    }, {
                        path: 'update',
                        name: 'nonEnrolledStudentsUpdate',
                        meta:{roles:['admin'], activatedModules :'controlAct',adminPerm : 'nonEnrolledStudents'},
                        component: () => import ('../components/NonEnrolledStudents/update.vue')
                    }
                ]
            },
            {
                path: 'parents',
                name: 'Parents',
                meta:{link:'parents',title:'parents',roles:['admin','manager','HOD'],adminPerm : 'parents'},
                component: () => import ('../components/Parents/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'ParentCreate',
                        meta:{roles:['admin','manager','HOD'],adminPerm : 'parents'},
                        component: () => import ('../components/Parents/create.vue')
                    }, {
                        path: 'update',
                        name: 'ParentUpdate',
                        meta:{roles:['admin','manager','HOD'], adminPerm : 'parents'},
                        component: () => import ('../components/Parents/update.vue')
                    }, {
                        path: 'show',
                        name: 'ParentShow',
                        meta:{ adminPerm : 'parents'},
                        component: () => import ('../components/Parents/show.vue')
                    }, {
                        path: 'show',
                        name: 'ParentSeen',
                        meta:{ adminPerm : 'parents'},
                        component: () => import ('../components/Parents/seen.vue')
                    },
                    {
                        path: 'import',
                        name: 'ParentImport',
                        meta:{roles:['admin','manager','HOD'],adminPerm : 'parents'},
                        component: () => import ('../components/Parents/import.vue')
                    },
                    {
                        path: 'waitingApproval',
                        name: 'WaitingApproval',
                        meta:{ adminPerm : 'parents'},
                        component: () => import ('../components/Parents/waitingApproval.vue')
                    },
                    {
                        path: 'show-parents',
                        name: 'showStudents',
                        meta:{roles:['admin','manager','HOD'],adminPerm : 'parents'},
                        component: () => import ('../components/Parents/showStudents.vue')
                    }
                ]
            },
            {
                path: 'grades-schedule',
                name: 'ClassSchedules',
                meta:{link:'classschedules',title:'classSch', activatedModules :'classSchAct',adminPerm : 'classSch'},
                component: () => import ('../components/ClassSchedules/index.vue'),
                children: [
                    {
                        path: 'details',
                        name: 'ClassScheduleDetails',
                        meta:{ activatedModules :'classSchAct',adminPerm : 'classSch'},
                        component: () => import ('../components/ClassSchedules/details.vue'),
                        children:[
                            {
                                path: 'update',
                                name: 'ClassScheduleUpdate',
                                meta:{ activatedModules :'classSchAct',adminPerm : 'classSch'},
                                component: () => import ('../components/ClassSchedules/update.vue')
                            },

                        ]
                    },
                    {
                        path: 'import',
                        name: 'ClassScheduleImport',
                        meta:{roles:['admin']},
                        component: () => import ('../components/ClassSchedules/import.vue')
                    }
                ]
            },
            {
                path: 'covering-sessions',
                name: 'CoveringSessions',
                meta:{link:'coveringSessions',title:'coveringSessionsList',roles:['admin','manager' ,'HOD'],adminPerm : 'coveringSessions'},
                component: () => import ('../components/CoveringSessions/list'),
                children: [
                    {
                        path: 'create',
                        name: 'CoveringSessionsCreate',
                        meta:{roles:['admin','student','parent','manager','ministerial','HOD'],adminPerm : 'coveringSessions'},
                        component: () => import ('../components/CoveringSessions/create')
                    }, {
                        path: 'update',
                        name: 'CoveringSessionsUpdate',
                        meta:{roles:['admin','student','parent','ministerial','HOD'], adminPerm : 'coveringSessions'},
                        component: () => import ('../components/CoveringSessions/update')
                    }
                ]
            },
            {
                path: 'study-material',
                name: 'StudyMaterial',
                meta:{link:'study-materials',title:'listStudyMaterial',roles:['admin','teacher','student','manager','ministerial','HOD'], activatedModules :'materialsAct',adminPerm : 'studyMaterial'},
                component: () => import ('../components/StudyMaterials/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'StudyMaterialCreate',
                        meta:{roles:['admin','teacher','manager'], activatedModules :'materialsAct',adminPerm : 'studyMaterial'},
                        component: () => import ('../components/StudyMaterials/create.vue')
                    }, {
                        path: 'update',
                        name: 'StudyMaterialUpdate',
                        meta:{ activatedModules :'materialsAct',adminPerm : 'studyMaterial'},
                        component: () => import ('../components/StudyMaterials/update.vue')
                    },
                    {
                        path: 'show',
                        name: 'StudyMaterialShow',
                        meta:{ activatedModules :'materialsAct',adminPerm : 'studyMaterial'},
                        component: () => import ('../components/StudyMaterials/show.vue')
                    },{
                        path: 'views',
                        name: 'StudyMaterialShowViews',
                        meta:{roles:['admin'], activatedModules :'materialsAct',adminPerm : 'studyMaterial'},
                        component: () => import ('../components/Shared/showViews')
                    }
                ]
            },
            {
                path: 'lessons',
                name: 'Lessons',
                meta:{link:'lessons',title:'lessons',roles:['admin','teacher','parent','student','manager','ministerial','HOD'], activatedModules :'lessons',adminPerm : 'lessons'},
                component: () => import ('../components/Lessons/list.vue'),
                children: [

                    {
                        path: 'create',
                        name: 'LessonCreate',
                        meta:{roles:['admin','teacher','manager'], activatedModules :'lessons',adminPerm : 'lessons'},
                        component: () => import ('../components/Lessons/create.vue')
                    }, {
                        path: 'update',
                        name: 'LessonUpdate',
                        meta:{roles:['admin','teacher','manager'], activatedModules :'lessons',adminPerm : 'lessons'},
                        component: () => import ('../components/Lessons/update.vue')
                    },
                    {
                        path: 'show',
                        name: 'LessonShow',
                        meta:{ activatedModules :'lessons',adminPerm : 'lessons'},
                        component: () => import ('../components/Lessons/show.vue')
                    }
                ]
            },
            {
                path: 'attendance',
                name: 'Attendance',
                meta:{link:'Attendance',title:'attendance',roles:['admin','teacher','ministerial','HOD'],adminPerm : 'Attendance'},
                component: () => import ('../components/Attendance/attendance.vue'),
                children: [
                    {
                        path: 'import',
                        name: 'AttendanceImport',
                        meta:{ adminPerm : 'attendance'},
                        component: () => import ('../components/Attendance/import.vue')
                    }
                ]
            },
            {
                path: 'promotion',
                name: 'Promotion',
                meta:{link:'promotion',title:'promoteStudents',roles:['admin'],adminPerm : 'Promotion'},
                component: () => import ('../components/Promotion/promotion.vue'),
                children: [
                    {
                        path: 'listStudents',
                        name: 'ListStudents',
                        meta:{roles:['admin'], adminPerm : 'Promotion'},
                        component: () => import ('../components/Promotion/list.vue')
                    }
                ]
            },
            {
                path: 'calender',
                name: 'Calender',
                meta:{link:'calender',title:'Calenderlist',roles:['admin','teacher','student','ministerial'], activatedModules :'calendarAct',adminPerm : 'Calender'},
                component: () => import ('../components/Calender/calender.vue')
            },
            {
                path: 'memos',
                name: 'Memos',
                meta:{link:'memos',title:'memos', activatedModules :'MemosAct',adminPerm : 'memos'},
                component: () => import ('../components/Memos/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'MemoCreate',
                        meta:{roles:['admin','manager','HOD'], activatedModules :'MemosAct',adminPerm : 'memos'},
                        component: () => import ('../components/Memos/create.vue')
                    }, {
                        path: 'update',
                        name: 'MemoUpdate',
                        meta:{roles:['admin','manager','HOD'], activatedModules :'MemosAct',adminPerm : 'memos'},
                        component: () => import ('../components/Memos/update.vue')
                    },
                    {
                        path: 'views',
                        name: 'MemosShowViews',
                        meta:{roles:['admin'], activatedModules :'MemosAct',adminPerm : 'memos'},
                        component: () => import ('../components/Shared/showViews')
                    },
                    {
                        path: 'show',
                        name: 'MemoShow',
                        meta:{ activatedModules :'MemosAct',adminPerm : 'memos'},
                        component: () => import ('../components/Memos/show.vue')
                    },
                ]
            },
            {
                path: 'homework',
                name: 'Homework',
                meta:{link:'homework', title:'lstHomework',activatedModules :'homeworkAct',adminPerm : 'homework'},
                component: () => import ('../components/Homework/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'HomeworkCreate',
                        meta:{roles:['admin','teacher','manager'], activatedModules :'homeworkAct',adminPerm : 'homework'},
                        component: () => import ('../components/Homework/create.vue')
                    }, {
                        path: 'update',
                        name: 'HomeworkUpdate',
                        meta:{roles:['admin','teacher','manager'], activatedModules :'homeworkAct',adminPerm : 'homework'},
                        component: () => import ('../components/Homework/update.vue')
                    }, {
                        path: 'show',
                        name: 'HomeworkShow',
                        meta:{ activatedModules :'homeworkAct',adminPerm : 'homework'},
                        component: () => import ('../components/Homework/show.vue')
                    },
                    {
                        path: 'views',
                        name: 'HomeworkShowViews',
                        meta:{roles:['admin'], activatedModules :'homeworkAct',adminPerm : 'homework'},
                        component: () => import ('../components/Shared/showViews')
                    },
                    {
                        path: 'upload-answer-files',
                        name: 'UploadAnswerFiles',
                        meta:{roles:['student'], activatedModules :'homeworkAct',adminPerm : 'Homework'},
                        component: () => import ('../components/Homework/answers/upload')
                    },
                    {
                        path: 'list.vue-answer-files',
                        name: 'ListAnswerFiles',
                        meta:{roles:['admin','teacher','manager'], activatedModules :'homeworkAct',adminPerm : 'Homework'},
                        component: () => import ('../components/Homework/answers/list')
                    }
                ]
            },
            {
                path: 'homework-status',
                name: 'homeworkStatus',
                component: () => import ('../components/HomeworkStatus/homework-status.vue')
            },
            {
                path: 'assignments',
                name: 'Assignments',
                meta:{link:'assignments',title:'listAssignments',roles:['admin','teacher','student','parent','manager','HOD'], activatedModules :'assignmentsAct',adminPerm : 'Assignments'},
                component: () => import ('../components/Assignments/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'AssignmentCreate',
                        meta:{roles:['admin','teacher','manager'], activatedModules :'assignmentsAct',adminPerm : 'Assignments'},
                        component: () => import ('../components/Assignments/create.vue')
                    },
                    {
                        path: 'show',
                        name: 'AssignmentShow',
                        meta:{ activatedModules :'assignmentsAct',adminPerm : 'Assignments'},
                        component: () => import ('../components/Assignments/show.vue')
                    },{
                        path: 'update',
                        name: 'AssignmentUpdate',
                        meta:{roles:['admin','teacher','manager'], activatedModules :'assignmentsAct',adminPerm : 'Assignments'},
                        component: () => import ('../components/Assignments/update.vue')
                    },{
                        path: 'views',
                        name: 'AssignmentsShowViews',
                        meta:{roles:['admin'], activatedModules :'assignmentsAct',adminPerm : 'Assignments'},
                        component: () => import ('../components/Shared/showViews')
                    }, {
                        path: 'assignment-answer-files',
                        name: 'AssignmentsUploadAnswerFiles',
                        meta:{roles:['student'], activatedModules :'assignmentsAct',adminPerm : 'Assignments'},
                        component: () => import ('../components/Assignments/answers/upload')
                    }, {
                        path: 'list.vue-assignment-answer-files',
                        name: 'ListAssignmentAnswerFiles',
                        meta:{roles:['admin','teacher','manager'], activatedModules :'assignmentsAct',adminPerm : 'Assignments'},
                        component: () => import ('../components/Assignments/answers/list')
                    }
                ]
            },
            {
                path: 'assignments-overview',
                name: 'AssignmentsStatus',
                mete:{title:'assignmentStatus'},
                component: () => import ('../components/AssignmentsStatus/assignments-status.vue')
            },
            {
                path: 'invalid-license',
                name: 'InvalidLicense',
                mete:{title:'Invalid License'},
                component: () => import ('../components/License/InvalidLicense')
            },
            {
                path: 'expired-license',
                name: 'ExpiredLicense',
                mete:{title:'Expired License'},
                component: () => import ('../components/License/InvalidLicense')
            },
            {
                path: 'invalid-url',
                name: 'InvalidUrl',
                mete:{title:'Invalid Url'},
                component: () => import ('../components/License/InvalidLicense')
            },
            {
                path: 'online-exam-type',
                name: 'OnlineExamTypes',
                meta:{link:'online-exam-types',title:'OnlineExamQuestionType',roles:['admin'],adminPerm : 'OnlineExamQuestionType'},
                component: () => import ('../components/OnlineExamTypes/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'OnlineExamTypeCreate',
                        meta:{ adminPerm : 'OnlineExamQuestionType'},
                        component: () => import ('../components/OnlineExamTypes/create.vue')
                    }, {
                        path: 'update',
                        name: 'OnlineExamTypeUpdate',
                        meta:{ adminPerm : 'OnlineExamQuestionType'},
                        component: () => import ('../components/OnlineExamTypes/update.vue')
                    }, {
                        path: 'show',
                        name: 'OnlineExamTypeSeen',
                        meta:{ adminPerm : 'OnlineExamQuestionType'},
                        component: () => import ('../components/OnlineExamTypes/seen.vue')
                    }
                ]
            },
            {
                path: 'onlineExams',
                name: 'OnlineExams',
                meta:{link:'onlineExams',title:'onlineExams', activatedModules :'onlineexamsAct',adminPerm : 'onlineExams'},
                component: () => import ('../components/OnlineExams/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'OnlineExamCreate',
                        meta:{roles:['admin','teacher','manager'], activatedModules :'onlineexamsAct',adminPerm : 'onlineExams'},
                        component: () => import ('../components/OnlineExams/create.vue')
                    }, {
                        path: 'update',
                        name: 'OnlineExamUpdate',
                        meta:{roles:['admin','teacher','manager'], activatedModules :'onlineexamsAct',adminPerm : 'onlineExams'},
                        component: () => import ('../components/OnlineExams/update.vue')
                    }, {
                        path: 'show',
                        name: 'OnlineExamShow',
                        meta:{ activatedModules :'onlineexamsAct',adminPerm : 'onlineExams'},
                        component: () => import ('../components/OnlineExams/show.vue')
                    }, {
                        path: 'show-online-exam-marks',
                        name: 'ShowOnlineExamMarks',
                        meta:{roles:['admin','teacher','manager'], activatedModules :'onlineexamsAct',adminPerm : 'onlineExams'},
                        component: () => import ('../components/OnlineExams/marks/list.vue')
                    },
                    {
                        path: 'take-exam',
                        name: 'TakeExam',
                        meta:{ activatedModules :'onlineexamsAct',adminPerm : 'onlineExams'},
                        component: () => import ('../components/OnlineExams/takeExam.vue')
                    },
                    {
                        path: 'views',
                        name: 'OnlineExamsShowViews',
                        meta:{roles:['admin'], activatedModules :'onlineexamsAct',adminPerm : 'onlineExams'},
                        component: () => import ('../components/Shared/showViews')
                    },
                ]
            },
            {
                path: 'quizzes',
                name: 'Quizzes',
                meta:{link:'quizzes',title:'quizes', adminPerm : 'quizes'},
                component: () => import ('../components/Quizzes/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'QuizzeCreate',
                        meta:{roles:['admin','teacher','manager'],adminPerm : 'quizes'},
                        component: () => import ('../components/Quizzes/create.vue')
                    }, {
                        path: 'update',
                        name: 'QuizzeUpdate',
                        meta:{roles:['admin','teacher','manager'], adminPerm : 'quizes'},
                        component: () => import ('../components/Quizzes/update.vue')
                    }, {
                        path: 'show',
                        name: 'QuizzeShow',
                        meta:{ adminPerm : 'quizes'},
                        component: () => import ('../components/Quizzes/show.vue')
                    },
                    {
                        path: 'views',
                        name: 'QuizzesShowViews',
                        meta:{roles:['admin'],adminPerm : 'quizes'},
                        component: () => import ('../components/Shared/showViews')
                    },
                    {
                        path: 'marks',
                        name: 'QuizzesMarks',
                        meta:{roles:['admin','teacher','manager'],adminPerm : 'quizes'},
                        component: () => import ('../components/Quizzes/marks/list')
                    },
                ]
            },
            {
                path: 'marks',
                name: 'SemesterDegree',
                meta:{link:'semester-degree',title:'addMarks',roles:['admin','manager'],adminPerm : 'addMarks'},
                component: () => import ('../components/SemesterDegree/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'SemesterDegreeCreate',
                        meta:{ adminPerm : 'addMarks'},
                        component: () => import ('../components/SemesterDegree/create.vue')
                    }, {
                        path: 'update',
                        name: 'SemesterDegreeUpdate',
                        meta:{ adminPerm : 'addMarks'},
                        component: () => import ('../components/SemesterDegree/update.vue')
                    }, {
                        path: 'show',
                        name: 'SemesterDegreeSeen',
                        meta:{ adminPerm : 'addMarks'},
                        component: () => import ('../components/SemesterDegree/seen.vue')
                    },
                    {
                        path: 'import',
                        name: 'MarksImport',
                        meta:{roles:['admin'],adminPerm : 'addMarks'},
                        component: () => import ('../components/SemesterDegree/import.vue')
                    }
                ]
            },
            {
                path: 'semesters',
                name: 'Semester',
                meta:{link:'semesters',title:'semesters',roles:['admin'], activatedModules :'controlAct',adminPerm : 'semesters'},
                component: () => import ('../components/Semester/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'SemesterCreate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'], activatedModules :'controlAct',adminPerm : 'semesters'},
                        component: () => import ('../components/Semester/create.vue')
                    }, {
                        path: 'update',
                        name: 'SemesterUpdate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'], activatedModules :'controlAct',adminPerm : 'semesters'},
                        component: () => import ('../components/Semester/update.vue')
                    }
                ]
            },
            {
                path: 'period-exams',
                name: 'PeriodExams',
                meta:{link:'period-exams',title:'semesterMonthes',roles:['admin'], activatedModules :'controlAct',adminPerm : 'semesterMonth'},
                component: () => import ('../components/PeriodExams/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'PeriodExamCreate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'], activatedModules :'controlAct',adminPerm : 'semesterMonth'},
                        component: () => import ('../components/PeriodExams/create.vue')
                    }, {
                        path: 'update',
                        name: 'PeriodExamUpdate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'], activatedModules :'controlAct',adminPerm : 'semesterMonth'},
                        component: () => import ('../components/PeriodExams/update.vue')
                    }
                ]
            },
            {
                path: 'mark-sheet',
                name: 'ExamsList',
                meta:{link:'ExamsList',title:'examsList',roles:['admin','teacher','student','parent','HOD','ministerial'],  activatedModules :'controlAct',adminPerm : 'examsList'},
                component: () => import ('../components/ExamsList/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'ExamsListCreate',
                        meta:{roles:['admin'], activatedModules :'controlAct',adminPerm : 'examsList'},
                        component: () => import ('../components/ExamsList/create.vue')
                    }, {
                        path: 'update',
                        name: 'ExamsListUpdate',
                        meta:{roles:['admin'], activatedModules :'controlAct',adminPerm : 'examsList'},
                        component: () => import ('../components/ExamsList/update.vue')
                    }, {
                        path: 'show',
                        name: 'ExamsListShow',
                        meta:{roles:['admin','HOD','student','parent','manager','ministerial'], activatedModules :'controlAct',adminPerm : 'examsList'},
                        component: () => import ('../components/ExamsList/show.vue')
                    }, {
                        path: 'comments',
                        name: 'ExamsComments',
                        meta:{roles:['admin'], activatedModules :'controlAct',adminPerm : 'examsList'},
                        component: () => import ('../components/ExamsList/comments.vue')
                    },
                    {
                        path: 'show-marks',
                        name: 'ExamShowMarks',
                        meta:{roles:['admin','teacher'], activatedModules :'controlAct',adminPerm : 'examsList'},
                        component: () => import ('../components/ExamsList/showMarks.vue')
                    },
                    {
                        path: 'show-mark-logs',
                        name: 'ExamShowMarkLogs',
                        meta:{roles:['admin'], activatedModules :'controlAct',adminPerm : 'examsList'},
                        component: () => import ('../components/ExamsList/showMarkLogs.vue')
                    },
                    {
                        path: 'import',
                        name: 'ExamMarksImport',
                        meta:{roles:['admin','teacher'], activatedModules :'controlAct',adminPerm : 'examsList'},
                        component: () => import ('../components/ExamsList/import.vue')
                    }
                ]
            },
            {
                path: 'examination-halls',
                name: 'ExaminationHalls',
                meta:{link:'examination-halls',title:'examination_halls',roles:['admin'], activatedModules :'controlAct',adminPerm : 'examination_halls'},
                component: () => import ('../components/ExaminationHalls/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'ExaminationHallCreate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'], activatedModules :'controlAct',adminPerm : 'examination_halls'},
                        component: () => import ('../components/ExaminationHalls/create.vue')
                    }, {
                        path: 'update',
                        name: 'ExaminationHallUpdate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'], activatedModules :'controlAct',adminPerm : 'examination_halls'},
                        component: () => import ('../components/ExaminationHalls/update.vue')
                    }, {
                        path: 'show',
                        name: 'ExaminationHallStudents',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'], activatedModules :'controlAct',adminPerm : 'examination_halls'},
                        component: () => import ('../components/ExaminationHalls/seen.vue')
                    },
                    {
                        path: 'update-student-hall',
                        name: 'ExaminationHallUpdateStudentHall',
                        meta:{ activatedModules :'controlAct',adminPerm : 'examination_halls'},
                        component: () => import ('../components/ExaminationHalls/student.vue')
                    }
                ]
            },
            {
                path: 'seat-numbers',
                name: 'SeatingNumbers',
                meta:{link:'SeatingNumbers',title:'sittingNumbers',roles:['admin'], activatedModules :'controlAct',adminPerm : 'sittingNumbers'},
                component: () => import ('../components/SeatingNumbers/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'SeatingNumberCreate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'],  activatedModules :'controlAct',adminPerm : 'sittingNumbers'},
                        component: () => import ('../components/SeatingNumbers/create.vue')
                    }
                ]
            },
            {
                path: 'security-numbers',
                name: 'SecurityNumbers',
                meta:{link:'SecurityNumbers',title:'listSecurityNumbers',roles:['admin'], activatedModules :'controlAct',adminPerm : 'SecurityNumbers'},
                component: () => import ('../components/SecurityNumbers/list.vue'),
            },
            {
                path: 'examination-hall-supervisor',
                name: 'Supervisor',
                meta:{link:'supervisor',title:'examinationHallSupervisor',roles:['admin'], activatedModules :'controlAct',adminPerm : 'examinationHallSupervisor'},
                component: () => import ('../components/Supervisor/list.vue'),
                children: [
                    {
                        path: 'update',
                        name: 'SupervisorUpdate',
                        meta:{roles:['admin','teacher','student','parent','manager','ministerial'], activatedModules :'controlAct',adminPerm : 'examinationHallSupervisor'},
                        component: () => import ('../components/Supervisor/update.vue')
                    }
                ]
            },
            {
                path: 'examination-hall-attendance',
                name: 'AttendanceHall',
                meta:{link:'attendance-hall',title:'examinationHallAttendance',roles:['admin','teacher','HOD'], activatedModules :'controlAct',adminPerm : 'examinationHallAttendance'},
                component: () => import ('../components/AttendanceHall/attendance.vue'),
            },
            {
                path: 'supervisors-report',
                name: 'SupervisorReport',
                meta:{link:'supervisor-report',title:'reportSupervisors', roles:['admin'], activatedModules :'controlAct',adminPerm : 'reportSupervisors'},
                component: () => import ('../components/SupervisorReport/report.vue'),
            },
            {
                path: 'certificates',
                name: 'Certificates',
                meta:{link:'certificates',title:'certificationReport',roles:['admin','student','parent','manager','HOD'], activatedModules :'controlAct',adminPerm : 'certificates'},
                component: () => import ('../components/Certificates/certificate.vue'),
            },
            {
                path: 'messages',
                name: 'Messages',
                meta:{link:'messages',title:'Messages', activatedModules :'messagesAct',adminPerm : 'messages'},
                component: () => import ('../components/Messages/messages.vue'),
                children: [{
                    path: 'show-message',
                    name: 'ComposeMessage',
                    meta:{ activatedModules :'messagesAct',adminPerm : 'messages'},
                    component: () => import ('../components/Messages/compose-message.vue')
                },
                    {
                        path: 'compose-message',
                        name: 'CreateMessage',
                        meta:{ activatedModules :'messagesAct',adminPerm : 'messages'},
                        component: () => import ('../components/Messages/create-messages')
                    }
                ]
            },
            {
                path: 'follow-up-messages',
                name: 'UserMessages',
                meta:{link:'user-messages',title:'followUpOnMessages',roles:['admin'], activatedModules :'messagesAct',adminPerm : 'Messages'},
                component: () => import ('../components/UserMessages/messages.vue'),
                children: [{
                    path: 'user-show-message',
                    name: 'UserComposeMessage',
                    meta:{ activatedModules :'messagesAct',adminPerm : 'Messages'},
                    component: () => import ('../components/UserMessages/compose-message')
                },

                ]
            },
            {
                path: 'approve-teacher-vacation',
                name: 'VacationTeacherApprove',
                meta:{link:'vacation-teacher-approve',title:'approveVacationForTeacher',roles:['admin','HOD','manager'], activatedModules :'vacationAct',adminPerm : 'approveVacationForTeacher'},
                component: () => import ('../components/VacationTeacherApprove/list.vue'),
            },
            {
                path: 'request-vacation',
                name: 'RequestVacation',
                meta:{link:'request-vacation',title:'selectVacationDates',roles:['teacher','student','parent'], activatedModules :'vacationAct',},
                component: () => import ('../components/RequestVacation/vacation.vue'),
            },
            {
                path: 'my-vacation',
                name: 'MyVacation',
                meta:{link:'my-vacation',title:'Vacation', activatedModules :'vacationAct'},
                component: () => import ('../components/RequestVacation/MyVacation'),
            },
            {
                path: 'my-attendance',
                name: 'myAttendance',
                meta:{link:'my-attendance',roles: ['parent','student'],title:'attendanceStatus', activatedModules :'attendanceAct'},
                component: () => import ('../components/Attendance/studentAttendance'),
            },
            {
                path: 'approve-student-vacation',
                name: 'VacationStudentApprove',
                meta:{link:'vacation-student-approve',title:'approveVacationStudent',roles:['admin','HOD','manager'], activatedModules :'vacationAct',adminPerm : 'approveVacationStudent'},
                component: () => import ('../components/VacationStudentApprove/list.vue'),
            },
            {
                path: 'mail-sms-templates',
                name: 'MailsmsTemplates',
                meta:{link:'mailsmsTemplates',title:'mailsmsTemplates',roles:['admin'], activatedModules :'mailSmsAct',adminPerm : 'mailsmsTemplates'},
                component: () => import ('../components/MailsmsTemplates/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'MailsmsTemplateCreate',
                        meta:{roles:['admin'],  activatedModules :'mailSmsAct',adminPerm : 'mailsmsTemplates'},
                        component: () => import ('../components/MailsmsTemplates/create.vue')
                    }, {
                        path: 'update',
                        name: 'MailsmsTemplateUpdate',
                        meta:{roles:['admin'],  activatedModules :'mailSmsAct',adminPerm : 'mailsmsTemplates'},
                        component: () => import ('../components/MailsmsTemplates/update.vue')
                    }, {
                        path: 'show',
                        name: 'MailsmsTemplateSeen',
                        meta:{roles:['admin'],  activatedModules :'mailSmsAct',adminPerm : 'mailsmsTemplates'},
                        component: () => import ('../components/MailsmsTemplates/seen.vue')
                    }
                ]
            },
            {
                path: 'zoom' ,
                name: 'Zoom',
                meta:{link:'zoom', title:'zoom', activatedModules :'zoom',adminPerm : 'zoom'},
                component: () => import ('../components/Zoom/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'ZoomCreate',
                        meta:{roles:['admin','teacher','manager','HOD'], activatedModules :'zoom',adminPerm : 'zoom'},
                        component: () => import ('../components/Zoom/create.vue')
                    }, {
                        path: 'show',
                        name: 'MeetingShow',
                        meta:{ activatedModules :'zoom',adminPerm : 'zoom'},
                        component: () => import ('../components/Zoom/show.vue')
                    },{
                        path: 'update',
                        name: 'ZoomUpdate',
                        meta:{roles:['admin','teacher','manager','HOD'],  activatedModules :'zoom',adminPerm : 'zoom'},
                        component: () => import ('../components/Zoom/update.vue')
                    }
                ]
            },
            {
                path: 'bigBlueButton',
                name: 'Meetings',
                meta:{link:'meetings',title:'onlineMeetings', activatedModules :'bigBlueButton',adminPerm : 'onlineMeetings'},
                component: () => import ('../components/Meetings/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'MeetingCreate',
                        meta:{roles:['admin','teacher','manager'], activatedModules :'bigBlueButton',adminPerm : 'onlineMeetings'},
                        component: () => import ('../components/Meetings/create.vue')
                    }
                ]
            },
            {
                path: 'library',
                name: 'Library',
                meta:{link:'library',title:'Library',roles:['admin','teacher','student','parent'], activatedModules :'library',adminPerm : 'Library'},
                component: () => import ('../components/Library/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'LibraryCreate',
                        meta:{roles:['admin'], activatedModules :'library',adminPerm : 'Library'},
                        component: () => import ('../components/Library/create.vue')
                    }, {
                        path: 'update',
                        name: 'LibraryUpdate',
                        meta:{roles:['admin'], activatedModules :'library',adminPerm : 'Library'},
                        component: () => import ('../components/Library/update.vue')
                    },
                    {
                        path: 'import',
                        name: 'LibraryImport',
                        meta:{roles:['admin'], activatedModules :'library',adminPerm : 'Library'},
                        component: () => import ('../components/Library/import.vue')
                    }
                ]
            },
            {
                path: 'lib-subscription',
                name: 'LibSubscription',
                meta:{link:'lib-subscription',title:'lib_subscription',roles:['admin'], activatedModules :'library',adminPerm : 'lib_subscription'},
                component: () => import ('../components/LibSubscription/library-subscription.vue')
            },
            {
                path: 'borrow-books',
                name: 'LibraryIssues',
                meta:{link:'library-issues',title:'library_issues',roles:['admin'], activatedModules :'library',adminPerm : 'library_issues'},
                component: () => import ('../components/LibraryIssues/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'LibraryIssuesCreate',
                        meta:{roles:['admin'], activatedModules :'library',adminPerm : 'library_issues'},
                        component: () => import ('../components/LibraryIssues/create.vue')
                    }, {
                        path: 'update',
                        name: 'LibraryIssuesUpdate',
                        meta:{roles:['admin'], activatedModules :'library',adminPerm : 'library_issues'},
                        component: () => import ('../components/LibraryIssues/update.vue')
                    }
                ]
            },
            {
                path: 'book-return',
                name: 'LibraryReturn',
                meta:{link:'library-return',title:'library_return',roles:['admin'], activatedModules :'library',adminPerm : 'library_return'},
                component: () => import ('../components/LibraryReturn/list.vue'),
            },
            {
                path: 'setup',
                name: 'Setup',
                meta:{link:'setup',title:'setup',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'setup'},
                component: () => import ('../components/Accounting/Setup/setup.vue'),
                children: [
                    {
                        path: 'visaAccount',
                        name: 'VisaAccount',
                        component: () => import ('../components/Accounting/Setup/visaAccount.vue')
                    }, {
                        path: 'payment-revert-account',
                        name: 'PaymentRevertAccount',
                        component: () => import ('../components/Accounting/Setup/paymentRevert.vue')
                    }, {
                        path: 'admissionVoucher',
                        name: 'AdmissionVoucher',
                        component: () => import ('../components/Accounting/Setup/admissionVoucher.vue')
                    }
                ]
            },
            {
                path: 'financial-year',
                name: 'FinancialYear',
                meta:{link:'financial-year',title:'financialYear',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'financialYear'},
                component: () => import ('../components/Accounting/FinancialYear/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'FinancialYearCreate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'financialYear'},
                        component: () => import ('../components/Accounting/FinancialYear/create.vue')
                    }, {
                        path: 'update',
                        name: 'FinancialYearUpdate',
                        component: () => import ('../components/Accounting/FinancialYear/update.vue')
                    },
                ]
            },
            {
                path: 'currencies',
                name: 'Currencies',
                meta:{link:'currencies',title:'currencies',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'currencies'},
                component: () => import ('../components/Accounting/Currencies/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'CurrencyCreate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'currencies'},
                        component: () => import ('../components/Accounting/Currencies/create.vue')
                    }, {
                        path: 'update',
                        name: 'CurrencyUpdate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'currencies'},
                        component: () => import ('../components/Accounting/Currencies/update.vue')
                    }
                ]
            },
            {
                path: 'accounts-groups',
                name: 'AccountsGroups',
                meta:{link:'accounts-groups',title:'accountsGroups',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'accountsGroups'},
                component: () => import ('../components/Accounting/AccountsGroups/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'AccountsGroupCreate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'accountsGroups'},
                        component: () => import ('../components/Accounting/AccountsGroups/create.vue')
                    }, {
                        path: 'update',
                        name: 'AccountsGroupUpdate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'accountsGroups'},
                        component: () => import ('../components/Accounting/AccountsGroups/update.vue')
                    }
                ]
            },
            {
                path: 'debenture-types',
                name: 'DebentureTypes',
                meta:{link:'debenture-typescos',title:'debentureTypes',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'debentureTypes'},
                component: () => import ('../components/Accounting/DebentureTypes/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'DebentureTypeCreate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'debentureTypes'},
                        component: () => import ('../components/Accounting/DebentureTypes/create.vue')
                    }, {
                        path: 'update',
                        name: 'DebentureTypeUpdate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'debentureTypes'},
                        component: () => import ('../components/Accounting/DebentureTypes/update.vue')
                    }
                ]
            },
            {
                path: 'cost-centers',
                name: 'CostCenters',
                meta:{link:'cost-centers',title:'costCenters',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'costCenters'},
                component: () => import ('../components/Accounting/CostCenters/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'CostCenterCreate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'costCenters'},
                        component: () => import ('../components/Accounting/CostCenters/create.vue')
                    }, {
                        path: 'update',
                        name: 'CostCenterUpdate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'costCenters'},
                        component: () => import ('../components/Accounting/CostCenters/update.vue')
                    }
                ]
            },
            {
                path: 'chart-of-accounts',
                name: 'ChartAccounts',
                meta:{link:'chart-accounts',title:'accounts',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'accounts'},
                component: () => import ('../components/Accounting/ChartAccounts/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'ChartAccountCreate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'accounts'},
                        component: () => import ('../components/Accounting/ChartAccounts/create.vue')
                    }, {
                        path: 'update',
                        name: 'ChartAccountUpdate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'accounts'},
                        component: () => import ('../components/Accounting/ChartAccounts/update.vue')
                    },{
                        path: 'import',
                        name: 'ChartAccountImport',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'accounts'},
                        component: () => import ('../components/Accounting/ChartAccounts/import.vue')
                    }
                ]
            },
            {
                path: 'opening-balances',
                name: 'OpeningBalances',
                meta:{link:'opening-balances',title:'openingBalances',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'openingBalances'},
                component: () => import ('../components/Accounting/OpeningBalances/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'OpeningBalanceCreate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'openingBalances'},
                        component: () => import ('../components/Accounting/OpeningBalances/create.vue')
                    }, {
                        path: 'update',
                        name: 'OpeningBalanceUpdate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'openingBalances'},
                        component: () => import ('../components/Accounting/OpeningBalances/update.vue')
                    },
                    {
                        path: 'import',
                        name: 'OpeningBalanceImport',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'openingBalances'},
                        component: () => import ('../components/Accounting/OpeningBalances/import.vue')
                    }
                ]
            },
            {
                path: 'payment-vouchers',
                name: 'PaymentVouchers',
                meta:{link:'payment-vouchers',title:'paymentVouchers',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'payment'},
                component: () => import ('../components/Accounting/PaymentVouchers/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'PaymentVoucherCreate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'payment'},
                        component: () => import ('../components/Accounting/PaymentVouchers/create.vue')
                    }, {
                        path: 'update',
                        name: 'PaymentVoucherUpdate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'payment'},
                        component: () => import ('../components/Accounting/PaymentVouchers/update.vue')
                    },  {
                        path: 'import',
                        name: 'PaymentVoucherImport',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'payment'},
                        component: () => import ('../components/Accounting/PaymentVouchers/import.vue')
                    },  {
                        path: 'show',
                        name: 'PaymentVoucherSeen',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'payment'},
                        component: () => import ('../components/Accounting/PaymentVouchers/seen.vue')
                    }
                ]
            },
            {
                path: 'order-payment-vouchers',
                name: 'OrderPayment',
                meta:{link:'order-payment',title:'orderPayment',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'orderPayment'},
                component: () => import ('../components/Accounting/OrderPayment/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'OrderPaymentCreate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'orderPayment'},
                        component: () => import ('../components/Accounting/OrderPayment/create.vue')
                    }, {
                        path: 'update',
                        name: 'OrderPaymentUpdate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'orderPayment'},
                        component: () => import ('../components/Accounting/OrderPayment/update.vue')
                    },{
                        path: 'show',
                        name: 'OrderPaymentSeen',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'orderPayment'},
                        component: () => import ('../components/Accounting/OrderPayment/seen.vue')
                    }
                ]
            },
            {
                path: 'receipt-vouchers',
                name: 'ReceiptVouchers',
                meta:{link:'receipt-vouchers',title:'receipt',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'receipt'},
                component: () => import ('../components/Accounting/ReceiptVouchers/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'ReceiptVoucherCreate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'receipt'},
                        component: () => import ('../components/Accounting/ReceiptVouchers/create.vue')
                    }, {
                        path: 'update',
                        name: 'ReceiptVoucherUpdate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'receipt'},
                        component: () => import ('../components/Accounting/ReceiptVouchers/update.vue')
                    },
                    {
                        path: 'import',
                        name: 'ReceiptVoucherImport',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'receipt'},
                        component: () => import ('../components/Accounting/ReceiptVouchers/import.vue')
                    },
                    {
                        path: 'show',
                        name: 'ReceiptVoucherSeen',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'receipt'},
                        component: () => import ('../components/Accounting/ReceiptVouchers/seen.vue')
                    }
                ]
            },
            {
                path: 'order-receipt-vouchers',
                name: 'OrderReceipt',
                meta:{link:'order-receipt',title:'orderReceipt',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'orderReceipt'},
                component: () => import ('../components/Accounting/OrderReceipt/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'OrderReceiptCreate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'orderReceipt'},
                        component: () => import ('../components/Accounting/OrderReceipt/create.vue')
                    }, {
                        path: 'update',
                        name: 'OrderReceiptUpdate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'orderReceipt'},
                        component: () => import ('../components/Accounting/OrderReceipt/update.vue')
                    }, {
                        path: 'show',
                        name: 'OrderReceiptSeen',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'orderReceipt'},
                        component: () => import ('../components/Accounting/OrderReceipt/seen.vue')
                    }
                ]
            },
            {
                path: 'forked-classworks',
                name: 'ForkedYearWorks',
                meta:{link:'forked-classworks',title:'forkedYearWorks',roles:['admin','HOD','manager','ministerial','teacher'], activatedModules :'forkedYearWorks',adminPerm : 'forkedYearWorks'},
                component: () => import ('../components/ForkedYearWorks/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'ForkedYearWorksCreate',
                        meta:{roles:['admin','HOD','manager'], activatedModules :'forkedYearWorks',adminPerm : 'forkedYearWorks'},
                        component: () => import ('../components/ForkedYearWorks/create.vue')
                    }, {
                        path: 'update',
                        name: 'ForkedYearWorksUpdate',
                        meta:{roles:['admin','HOD','manager'], activatedModules :'forkedYearWorks',adminPerm : 'forkedYearWorks'},
                        component: () => import ('../components/ForkedYearWorks/update.vue')
                    }, {
                        path: 'show',
                        name: 'ForkedYearWorksSeen',
                        meta:{roles:['admin','HOD','manager'], activatedModules :'forkedYearWorks',adminPerm : 'forkedYearWorks'},
                        component: () => import ('../components/ForkedYearWorks/show.vue')
                    },
                    {
                        path: 'show-marks',
                        name: 'ForkedYearWorksShowMarks',
                        meta:{roles:['admin','HOD','manager','ministerial','teacher'], activatedModules :'forkedYearWorks',adminPerm : 'forkedYearWorks'},
                        component: () => import ('../components/ForkedYearWorks/ShowMarks.vue')
                    },
                    {
                        path: 'import',
                        name: 'ForkedYearWorksImport',
                        meta:{roles:['admin'], activatedModules :'forkedYearWorks',adminPerm : 'forkedYearWorks'},
                        component: () => import ('../components/ForkedYearWorks/import.vue')
                    }
                ]
            },
            {
                path: 'journal-vouchers',
                name: 'JournalVouchers',
                meta:{link:'journal-vouchers',title:'journalVouchers',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'journalVouchers'},
                component: () => import ('../components/Accounting/JournalVouchers/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'JournalVoucherCreate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'journalVouchers'},
                        component: () => import ('../components/Accounting/JournalVouchers/create.vue')
                    }, {
                        path: 'update',
                        name: 'JournalVoucherUpdate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'journalVouchers'},
                        component: () => import ('../components/Accounting/JournalVouchers/update.vue')
                    },{
                        path: 'show',
                        name: 'JournalVoucherSeen',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'journalVouchers'},
                        component: () => import ('../components/Accounting/JournalVouchers/seen.vue')
                    }
                ]
            },
            {
                path: 'taxes',
                name: 'Taxes',
                meta:{link:'taxes',title:'taxes',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'taxes'},
                component: () => import ('../components/Accounting/Taxes/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'TaxCreate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'taxes'},
                        component: () => import ('../components/Accounting/Taxes/create.vue')
                    }, {
                        path: 'update',
                        name: 'TaxUpdate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'taxes'},
                        component: () => import ('../components/Accounting/Taxes/update.vue')
                    }
                ]
            },
            {
                path: 'agencies',
                name: 'Agencies',
                meta:{link:'agencies',title:'agencies',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'agencies'},
                component: () => import ('../components/Accounting/Agencies/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'AgencyCreate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'agencies'},
                        component: () => import ('../components/Accounting/Agencies/create.vue')
                    }, {
                        path: 'update',
                        name: 'AgencyUpdate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'agencies'},
                        component: () => import ('../components/Accounting/Agencies/update.vue')
                    }
                ]
            },
            {
                path: 'taxes-allocations',
                name: 'TaxesAllocation',
                meta:{link:'taxes-allocations',title:'taxAllocations',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'taxAllocations'},
                component: () => import ('../components/Accounting/TaxesAllocation/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'TaxesAllocationCreate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'taxAllocations'},
                        component: () => import ('../components/Accounting/TaxesAllocation/create.vue')
                    }, {
                        path: 'update',
                        name: 'TaxesAllocationUpdate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'taxAllocations'},
                        component: () => import ('../components/Accounting/TaxesAllocation/update.vue')
                    }
                ]
            },
            {
                path: 'tax-categories',
                name: 'TaxCategories',
                meta:{link:'tax-categories',title:'TaxCategories',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'TaxCategories'},
                component: () => import ('../components/Accounting/TaxCategories/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'TaxCategoryCreate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'TaxCategories'},
                        component: () => import ('../components/Accounting/TaxCategories/create.vue'),
                    }, {
                        path: 'update',
                        name: 'TaxCategoryUpdate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'TaxCategories'},
                        component: () => import ('../components/Accounting/TaxCategories/update.vue'),
                    }
                ]
            },
            {
                path: 'fee-group',
                name: 'FeeGroup',
                meta:{link:'fee-group',title:'FeeGroups', roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'feeGroups'},
                component: () => import ('../components/Accounting/FeeGroup/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'FeeGroupCreate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'feeGroups'},
                        component: () => import ('../components/Accounting/FeeGroup/create.vue')
                    }, {
                        path: 'update',
                        name: 'FeeGroupUpdate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'feeGroups'},
                        component: () => import ('../components/Accounting/FeeGroup/update.vue')
                    }
                ]
            },
            {
                path: 'fee-types',
                name: 'FeeType',
                meta:{link:'fee-types',title:'FeeTypes',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'feeTypes'},
                component: () => import ('../components/Accounting/FeeType/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'FeeTypeCreate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'feeTypes'},
                        component: () => import ('../components/Accounting/FeeType/create.vue')
                    }, {
                        path: 'update',
                        name: 'FeeTypeUpdate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'feeTypes'},
                        component: () => import ('../components/Accounting/FeeType/update.vue')
                    }
                ]
            },
            {
                path: 'fee-discount',
                name: 'FeeDiscount',
                meta:{link:'ee-discount',title:'FeeDiscount',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'feeDiscount'},
                component: () => import ('../components/Accounting/FeeDiscount/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'FeeDiscountCreate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'feeDiscount'},
                        component: () => import ('../components/Accounting/FeeDiscount/create.vue')
                    }, {
                        path: 'update',
                        name: 'FeeDiscountUpdate',
                        meta:{ activatedModules :'generalLedgerAct',adminPerm : 'feeDiscount'},
                        component: () => import ('../components/Accounting/FeeDiscount/update.vue')
                    },
                ]
            },
            {
                path: 'invoices',
                name: 'Invoices',
                meta:{link:'invoices',title:'Invoices',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'invoices'},
                component: () => import ('../components/Accounting/Invoices/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'InvoiceCreate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'invoices'},
                        component: () => import ('../components/Accounting/Invoices/create.vue')
                    }, {
                        path: 'update',
                        name: 'InvoiceUpdate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'invoices'},
                        component: () => import ('../components/Accounting/Invoices/update.vue')
                    }, {
                        path: 'show',
                        name: 'InvoiceSeen',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'invoices'},
                        component: () => import ('../components/Accounting/Invoices/seen.vue')
                    },{
                        path: 'bankAnnouncement',
                        name: 'InvoiceBankAnnouncement',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'invoices'},
                        component: () => import ('../components/Accounting/Invoices/bank-announcement.vue')
                    },
                ]
            },
            {
                path: 'closures',
                name: 'Closures',
                meta:{link:'closures',title:'closures',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'closures'},
                component: () => import ('../components/Accounting/Closures/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'ClosureClose',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'closures'},
                        component: () => import ('../components/Accounting/Closures/close.vue')
                    }
                ]
            },
            {
                path: 'payment-revert',
                name: 'PaymentRevert',
                meta:{link:'payment-revert',title:'listPaymentRevert',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'paymentRevert'},
                component: () => import ('../components/Accounting/PaymentRevert/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'PaymentRevertCreate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'paymentRevert'},
                        component: () => import ('../components/Accounting/PaymentRevert/create.vue')
                    }
                ]
            },
            {
                path: 'post-entries',
                name: 'PostEntries',
                meta:{link:'post-entries',title:'PostEntries',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'PostEntries'},
                component: () => import ('../components/Accounting/PostEntries/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'PostEntriesCreate',
                        meta:{roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'PostEntries'},
                        component: () => import ('../components/Accounting/PostEntries/create.vue')
                    }
                ]
            },
            {
                path: 'reports/account-statement-report',
                name: 'AccountStatement',
                meta:{link:'reports/account-statement',title:'accountStatement',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'accountStatement'},
                component: () => import ('../components/Accounting/Reports/AccountStatement/account-statement.vue'),
            },
            {
                path: 'reports/payment-report',
                name: 'Payment',
                meta:{link:'reports/payment',title:'paymentsReport',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'paymentsReport'},
                component: () => import ('../components/Accounting/Reports/Payment/payment.vue'),
            },
            {
                path: 'reports/receipt-reports',
                name: 'Receipt',
                meta:{link:'reports/receipt',title:'receiptsReport',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'receiptsReport'},
                component: () => import ('../components/Accounting/Reports/Receipt/receipt.vue'),
            },
            {
                path: 'reports/journal-voucher-report',
                name: 'Journal',
                meta:{link:'reports/journal',title:'JournalVoucherReport',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'Journal'},
                component: () => import ('../components/Accounting/Reports/Journal/journal.vue'),
            },
            {
                path: 'reports/public-journal-report',
                name: 'PublicJournal',
                meta:{link:'reports/ublic-journal',title:'journalReport',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'journalReport'},
                component: () => import ('../components/Accounting/Reports/PublicJournal/public-journal.vue'),
            },
            {
                path: 'reports/balance-sheet-report',
                name: 'BalanceSheet',
                meta:{link:'reports/balance-sheet',title:'balanceSheetReport',roles:['admin'], activatedModules :'generalLedgerAct',adminPerm : 'balanceSheetReport'},
                component: () => import ('../components/Accounting/Reports/BalanceSheet/balance-sheet.vue'),
            },
            {
                path: 'reports/profit-loss-report',
                name: 'ProfitLoss',
                meta:{link:'reports/profit-loss',title:'profitLossReport',roles:['admin']}, activatedModules :'generalLedgerAct',adminPerm : 'profitLossReport',
                component: () => import ('../components/Accounting/Reports/ProfitLoss/profit-loss.vue'),
            },
            {
                path: 'reports/trial-balance',
                name: 'TrialBalance',
                meta:{link:'reports/trial-balance',title:'trialBalance',roles:['admin']}, activatedModules :'generalLedgerAct',adminPerm : 'trialBalance',
                component: () => import ('../components/Accounting/Reports/TrialBalance/trial-balance.vue'),
            },
            {
                path: 'reports/opening-balance-report',
                name: 'OpeningBalance',
                meta:{link:'reports/trial-balance',title:'openingBalanceReport',roles:['admin']}, activatedModules :'generalLedgerAct',adminPerm : 'openingBalanceReport',
                component: () => import ('../components/Accounting/Reports/OpeningBalance/opening-balance.vue'),
            },
            {
                path: 'static-pages',
                name: 'StaticPages',
                meta:{link:'static-pages',title:'staticPages',roles:['admin'], activatedModules :'staticpagesAct',adminPerm : 'staticPages'},
                component: () => import ('../components/StaticPages/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'StaticPageCreate',
                        meta:{roles:['admin'], activatedModules :'staticpagesAct',adminPerm : 'staticPages'},
                        component: () => import ('../components/StaticPages/create.vue')
                    }, {
                        path: 'update',
                        name: 'StaticPageUpdate',
                        meta:{roles:['admin'], activatedModules :'staticpagesAct',adminPerm : 'staticPages'},
                        component: () => import ('../components/StaticPages/update.vue')
                    }, {
                        path: 'show/:id',
                        name: 'StaticPageShow',
                        meta:{roles:['admin','student'], activatedModules :'staticpagesAct',adminPerm : 'staticPages'},
                        component: () => import ('../components/StaticPages/show.vue')
                    }
                ]
            }
            ,
            {
                path: 'show/:id',
                name: 'StaticPageShow',
                meta:{roles:['admin','student'], activatedModules :'staticpagesAct',adminPerm : 'staticPages'},
                component: () => import ('../components/StaticPages/show.vue')
            },
            {
                path: 'general-settings',
                name: 'Settings',
                meta:{link:'settings',title:'adminTasks',roles:['admin'],adminPerm : 'generalSettings'},
                component: () => import ('../components/Settings/list.vue'),
                children: [
                    {
                        path: 'activated-modules',
                        name: 'ActivatedModules',
                        meta:{roles:['admin'],adminPerm : 'generalSettings'},
                        component: () => import ('../components/Settings/activated-modules.vue')
                    }, {
                        path: 'payments-settings',
                        name: 'PaymentsSettings',
                        meta:{roles:['admin'],adminPerm : 'generalSettings'},
                        component: () => import ('../components/Settings/payments-settings.vue')
                    }, {
                        path: 'mail-options-SMS',
                        name: 'MailOptionsSMS',
                        meta:{roles:['admin'],adminPerm : 'generalSettings'},
                        component: () => import ('../components/Settings/mail-options-SMS.vue')
                    }, {
                        path: 'vacation-settings',
                        name: 'VacationSettings',
                        meta:{roles:['admin'],adminPerm : 'generalSettings'},
                        component: () => import ('../components/Settings/vacation-settings.vue')
                    }, {
                        path: 'frontend-CMS',
                        name: 'FrontendCMS',
                        meta:{roles:['admin'],adminPerm : 'generalSettings'},
                        component: () => import ('../components/Settings/frontend-CMS.vue')
                    }, {
                        path: 'look-feel',
                        name: 'LookFeel',
                        meta:{roles:['admin'],adminPerm : 'generalSettings'},
                        component: () => import ('../components/Settings/look-feel.vue')
                    }, {
                        path: 'report-header',
                        name: 'ReportHeader',
                        meta:{roles:['admin'],adminPerm : 'generalSettings'},
                        component: () => import ('../components/Settings/report-header.vue')
                    }, {
                        path: 'notifications',
                        name: 'Notifications',
                        meta:{roles:['admin'], adminPerm : 'generalSettings'},
                        component: () => import ('../components/Settings/notifications.vue')
                    }, {
                        path: 'activated-email-SMS',
                        name: 'ActivatedEmailSMS',
                        meta:{roles:['admin'],adminPerm : 'generalSettings'},
                        component: () => import ('../components/Settings/activated-email-SMS.vue')
                    }, {
                        path: 'SQl-configerations',
                        name: 'SQl_Configerations',
                        meta:{roles:['admin'],adminPerm : 'generalSettings'},
                        component: () => import ('../components/Settings/SQl-configerations.vue')
                    }, {
                        path: 'big-blue-button',
                        name: 'BigBlueButton',
                        meta:{roles:['admin'],adminPerm : 'generalSettings'},
                        component: () => import ('../components/Settings/big-blue-button.vue')
                    }
                ]
            },
            {
                path: 'mobile-notifications',
                name: 'MobileNotifications',
                meta:{link:'mobile-notifications',title:'mobileNotifications',roles:['admin'],activatedModules :'sendNotifAct',adminPerm : 'mobileNotifications'},
                component: () => import ('../components/MobileNotifications/mobile-notifications.vue'),
                children: [
                    {
                        path: 'history',
                        name: 'historyMobileNotifications',
                        meta:{roles:['admin'],activatedModules :'sendNotifAct',adminPerm : 'mobileNotifications'},
                        component: () => import ('../components/MobileNotifications/history.vue')
                    }
                ]
            },
            {
                path: 'mail-sms',
                name: 'MailSMS',
                meta:{link:'link',title:'mailsms',roles:['admin'],activatedModules :'mailSmsAct',adminPerm : 'mailsms'},
                component: () => import ('../components/MailSMS/mail-sms.vue'),
                children: [
                    {
                        path: 'history',
                        name: 'historyMail',
                        meta:{roles:['admin'],activatedModules :'mailSmsAct',adminPerm : 'mailsms'},
                        component: () => import ('../components/MailSMS/history.vue')
                    }
                ]
            },
            {
                path: 'signatures',
                name: 'Signatures',
                meta:{link:'signatures',title:'signatures',roles:['admin'],adminPerm : 'signatures'},
                component: () => import ('../components/Signatures/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'createSignature',
                        meta:{roles:['admin'],adminPerm : 'signatures'},
                        component: () => import ('../components/Signatures/create.vue')
                    },
                    {
                        path: 'update',
                        name: 'UpdateSignature',
                        meta:{roles:['admin'],adminPerm : 'signatures'},
                        component: () => import ('../components/Signatures/update.vue')
                    }
                ]
            },
            {
                path: 'languages',
                name: 'Languages',
                meta:{link:'languages',title:'listLanguage',roles:['admin'],adminPerm : 'Languages'},
                component: () => import ('../components/Languages/list.vue'),
            },
            {
                path: 'administrators',
                name: 'Administrators',
                meta:{link:'administrators',title:'listAdministrators',roles:['admin'],adminPerm : 'Administrators'},
                component: () => import ('../components/Administrators/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'createAdministrators',
                        meta:{roles:['admin'], adminPerm : 'Administrators'},
                        component: () => import ('../components/Administrators/create.vue')
                    },
                    {
                        path: 'show',
                        name: 'showAdministrators',
                        meta:{roles:['admin'], adminPerm : 'Administrators'},
                        component: () => import ('../components/Administrators/show.vue')
                    }, {
                        path: 'update',
                        name: 'updateAdministrators',
                        meta:{roles:['admin'], adminPerm : 'Administrators'},
                        component: () => import ('../components/Administrators/update.vue')
                    }
                ]
            },
            {
                path: 'custom-menu',
                name: 'CustomMenu',
                meta:{link:'custom-menu',title:'customMenu',roles:['admin'] , adminPerm : 'customMenu'},
                component: () => import ('../components/CustomMenu/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'createCustomMenu',
                        meta:{roles:['admin'] , adminPerm : 'CustomMenu'},
                        component: () => import ('../components/CustomMenu/create.vue')
                    }, {
                        path: 'update',
                        name: 'updateCustomMenu',
                        meta:{roles:['admin'] , adminPerm : 'CustomMenu'},
                        component: () => import ('../components/CustomMenu/update.vue')
                    }
                ]
            },
            {
                path: 'branches',
                name: 'Branches',
                meta:{link:'branches',title:'branches',roles:['admin'], activatedModules :'branchesAct',adminPerm : 'branches'},
                component: () => import ('../components/Branches/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'BranchesCreate',
                        meta:{ activatedModules :'branchesAct',adminPerm : 'branches'},
                        component: () => import ('../components/Branches/create.vue')
                    }, {
                        path: 'update',
                        name: 'BranchesUpdate',
                        meta:{ activatedModules :'branchesAct',adminPerm : 'branches'},
                        component: () => import ('../components/Branches/update.vue')
                    }
                ]
            },
            {
                path: 'departments',
                name: 'Departments',
                meta:{link:'departments',title:'departments',roles:['admin'], activatedModules :'departmentAct',adminPerm : 'departments'},
                component: () => import ('../components/Departments/list.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'DepartmentsCreate',
                        meta:{ activatedModules :'departmentAct',adminPerm : 'departments'},
                        component: () => import ('../components/Departments/create.vue')
                    }, {
                        path: 'update',
                        name: 'DepartmentsUpdate',
                        meta:{ activatedModules :'departmentAct',adminPerm : 'departments'},
                        component: () => import ('../components/Departments/update.vue')
                    }
                ]
            },
            {
                path: 'dbexports',
                name: 'Dbexports',
                meta:{link:'dbexports',title:'dbExport'},
                component: () => import ('../components/Dbexports/export.vue'),
                children: [
                    {
                        path: 'import',
                        name: 'DbexportImport',
                        component: () => import ('../components/Dbexports/import.vue')
                    }
                ]
            },
            {
                path: 'license',
                name: 'AddLicense',
                meta:{link:'license',title:'license'},
                component: () => import ('../components/AddLicense/license.vue'),
            },
            {
                path: 'reports',
                name: 'Report',
                meta:{link:'reports',title:'Reports',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/report.vue'),
            },
            {
                path: 'report31',
                name: 'Report31',
                meta:{link:'link',title:'Reports31',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/EG/report31.vue'),

            },
            {
                path: 'report41',
                name: 'Report41',
                meta:{link:'link',title:'Reports41',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/EG/report41.vue'),

            },

            {
                path: 'report12',
                name: 'Report12',
                meta:{link:'link',title:'Reports12',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/EG/report12.vue'),

            },
            {
                path: 'reports/attendance-report',
                name: 'AllAttendance',
                meta:{link:'link',title:'attendanceReport',roles:['admin','manager','HOD'], activatedModules :'attendanceAct',adminPerm : 'attendance'},
                component: () => import ('../components/Report/all-attendance.vue'),
            },
            {
                path: 'reports/students-birthday',
                name: 'StudentsBirthday',
                meta:{link:'link',title:'studentsBirthday',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/students-birthday.vue'),
            },
            {
                path: 'reports/second-language',
                name: 'SecondLanguage',
                meta:{link:'link',title:'secondLanguageReport',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/second-language.vue'),
            },
            {
                path: 'reports/messages',
                name: 'MessagesReport',
                meta:{link:'link',title:'Messages',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/messages.vue'),
            },
            {
                path: 'reports/alnahda',
                name: 'Alnahda',
                meta:{link:'link',title:'alnahdaSchools'},
                component: () => import ('../components/Report/Alnahda/alnahda.vue'),
                children: [
                    {
                        path: 'alnahda-year-works',
                        name: 'alnahdaYearWorks',
                        component: () => import ('../components/Report/Alnahda/alnahda-year-works.vue')
                    }, {
                        path: 'academy-year-details',
                        name: 'acYearDetails',
                        component: () => import ('../components/Report/Alnahda/academy-year-details.vue')
                    }, {
                        path: 'yemen-yearly-degree',
                        name: 'yemenYearlyDegree',
                        component: () => import ('../components/Report/Alnahda/yemen-yearly-degree.vue')
                    }, {
                        path: 'marks-results',
                        name: 'marksResults',
                        component: () => import ('../components/Report/Alnahda/marks-results.vue')
                    }, {
                        path: 'turkey-department',
                        name: 'TurkeyDepartment',
                        component: () => import ('../components/Report/Alnahda/turkey-department.vue')
                    },
                    // {
                    //     path: 'certificates',
                    //     name: 'Certificates',
                    //     component: () => import ('../components/Report/Alnahda/certificates.vue')
                    // },

                    {
                        path: 'yearly-degree',
                        name: 'yearlyDegree',
                        component: () => import ('../components/Report/Alnahda/yearly-degree.vue')
                    }
                ]
            },
            {
                path: 'reports/users-statistics',
                name: 'UsersStatistics',
                meta:{link:'link',title:'UsersStats',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/users-statistics.vue'),
            },{
                path: 'reports/admission-report',
                name: 'admissionReport',
                meta:{link:'link',title:'AdmissionReport',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/admission-report.vue'),
            },
            {
                path: 'reports/students-attendance',
                name: 'studentAttendance',
                meta:{link:'link',title:'studentAttendance'},
                component: () => import ('../components/Report/student-attendance.vue'),
            },
            {
                path: 'reports/nationality',
                name: 'nationality',
                meta:{link:'link',title:'studentNationality'},
                component: () => import ('../components/Report/nationality.vue'),
            },
            {
                path: 'reports/vacation',
                name: 'Vacation',
                meta:{link:'link',title:'Vacation',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/vacation.vue'),
            },
            {
                path: 'reports/student-informations',
                name: 'studentInformations',
                meta:{link:'link',title:'studentInformations',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/student-informations.vue'),
            },
            {
                path: 'reports/parent-information',
                name: 'parentInformations',
                meta:{link:'link',title:'parentInfo',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/parent-informations.vue'),
            },
            {
                path: 'reports/school-statistic-report',
                name: 'schoolStatistic',
                meta:{link:'link',title:'UsersStats',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/school-statistic.vue'),
            },
            {
                path: 'reports/classes-list',
                name: 'classesList',
                meta:{link:'link',title:'classesList',roles:['admin','manager','HOD'],adminPerm : 'classes'},
                component: () => import ('../components/Report/classes-list.vue'),
            },
            {
                path: 'reports/exams-report',
                name: 'examsReport',
                meta:{link:'link',title:'examsReport',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/exams-report.vue'),
            },
            {
                path: 'reports/teacher-vacation',
                name: 'teacherVacation',
                meta:{link:'link',title:'teacherVacation'},
                component: () => import ('../components/Report/teacher-vacation.vue'),
            },
            {
                path: 'reports/taxes',
                name: 'TaxesReport',
                meta:{link:'link',title:'taxesReport'},
                component: () => import ('../components/Report/taxes.vue'),
            },
            {
                path: 'reports/exams-results',
                name: 'examsResults',
                meta:{link:'link',title:'examsResults',roles:['admin','parent','HOD']},
                component: () => import ('../components/Report/exams-results.vue'),
            },
            {
                path: 'reports/marks-log',
                name: 'marksLog',
                meta:{link:'link',title:'markLog',roles:['admin']},
                component: () => import ('../components/Report/marks-log.vue'),
            },
            {
                path: 'reports/sheets',
                name: 'Sheets',
                meta:{link:'link',title:'sheets',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/sheets.vue'),
            },
            {
                path: 'reports/book-receipt',
                name: 'bookReceipt',
                meta:{link:'link',title:'bookReceipt',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/book-receipt.vue'),
            },
            {
                path: 'reports/DailyPaidReport',
                name: 'DailyPaidReport',
                meta:{link:'link',title:'DailyPaidReport',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/dailyPaidReport.vue'),
            },
            {
                path: 'reports/dailyPaidCollectedReportH1',
                name: 'DailyPaidCollectedReportH1',
                meta:{link:'link',title:'DailyPaidCollectedReportH1',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/dailyPaidCollectedReportH1.vue'),
            },
            {
                path: 'reports/average-monthly-score-average',
                name: 'AverageMonthlyScoreAverage',
                meta:{link:'link',title:'AverageMonthlyScoreAverage',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/AverageMonthlyScoreAverage.vue'),
            },
            {
                path: 'reports/average-months-scores-average',
                name: 'AverageMonthsScoresAverage',
                meta:{link:'link',title:'AverageMonthsScoresAverage',roles:['admin','manager','HOD']},
                component: () => import ('../components/Report/AverageMonthesScoresAverage.vue'),
            },
            {
                path: 'reports/report-card',
                name: 'reportCard',
                meta:{link:'link',title:'reportCard',roles:['admin','parent','student']},
                component: () => import ('../components/Report/report-card.vue'),
            },
            {
                path: 'account/invoices',
                name: 'AccountInvoices',
                meta:{title:'Invoices'},
                component: () => import ('../components/Account/invoices.vue'),
            },
            {
                path: 'account/change-profile/data',
                name: 'AccountChangeProfileData',
                meta:{title:'ChgProfileData'},
                component: () => import ('../components/Account/profileData.vue'),
            },
            {
                path: 'account/change-profile/email',
                name: 'AccountChangeEmail',
                meta:{title:'chgEmailAddress'},
                component: () => import ('../components/Account/changeEmail.vue'),
            },
            {
                path: 'account/change-profile/password',
                name: 'AccountChangePassword',
                meta:{title:'chgPassword'},
                component: () => import ('../components/Account/changePassword.vue'),
            },
            {
                path: 'account/change-profile/zoom',
                name: 'AccountChangeZoom',
                meta:{title:'zoom'},
                component: () => import ('../components/Account/changeZoom.vue'),
            },
            {
                path: 'profileinfo',
                name: 'ProfileInfo',
                meta:{title:'AccountSettings'},
                component: () => import ('../components/ProfileInfo/List.vue'),

            },
            {
                path: 'notifications-view-all',
                name: 'NotificationsViewAll',
                meta:{link:'notifications',title:'notifications'},
                component: () => import ('../components/Notification/list.vue'),
            },
        ]
    },
    {
        path: '/:schoolName/login',
        name: 'Login',
        meta:{title:'signIn'},
        component: Login
    },
    {
        path: '/:schoolName/contact-us',
        name: 'ContactUs',
        meta:{title:'contactUs'},
        component: ContactUs
    },
    {
        path: '/:schoolName/forget-password',
        name: 'ForgetPassword',
        meta:{title:'restorePwd'},
        component: ForgetPassword
    },
    {
        path: "/:schoolName/:catchAll(.*)",
        name: "NotFound",
        meta:{title:'errorPageMsg'},
        component: () => import ('../components/PageNotFound'),
    }

]



const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeResolve((to, from, next) => {

    if(to.query.s) {
        console.log("11111111");
        return next( '/' + to.query.s);
    }
    const role = localStorage.getItem('role');
    const dashboard =  JSON.parse(localStorage.getItem('dashboard'));

    if(dashboard && dashboard.adminPerm) {
        const adminPerm = to.matched.some(route => {
            return route.meta.adminPerm && role == "admin" && dashboard.adminPerm != "full" && !dashboard.adminPerms.includes(route.meta.adminPerm)
        })


        const activated = to.matched.some(route => {
            return route.meta.activatedModules && !dashboard.activatedModules.includes(route.meta.activatedModules)
        })


        if (activated || adminPerm) {
            return next( '/' + localStorage.getItem('schoolName'));
        }
    }




    const lacksRole = to.matched.some(route => {
        return route.meta.roles && !route.meta.roles.includes(role)
    })



    if (lacksRole) {
        return next( '/' + localStorage.getItem('schoolName'));
    }

    if(to.params.schoolName != localStorage.getItem('schoolName')) {
        if(localStorage.getItem('tokens')) {
            let tokens = JSON.parse(localStorage.getItem('tokens'));
            let index = tokens.findIndex(record => record.schoolName === to.params.schoolName);
            if (index == -1) {
                store.dispatch('logout');
            }else {
                localStorage.setItem('role',tokens[index].role)
                localStorage.setItem('token',tokens[index].token)
                localStorage.setItem('expirationDate',tokens[index].expirationDate)
                localStorage.setItem('storageName',tokens[index].storageName)
                window.location = "/"+to.params.schoolName;
            }
        }

    }


    axios.defaults.params = {}
    axios.defaults.params['s'] =to.params.schoolName;

    localStorage.setItem('schoolName',to.params.schoolName)


    const publicPages = ['Login','InvalidLicense', 'ForgetPassword', 'ContactUs'];
    const authRequired = !publicPages.includes(to.name);
    const loggedIn = store.state.token;

    if(!localStorage.getItem('tokens') && authRequired) {
        store.dispatch('logout');
    }
    // if(to.path == '/') {
    //     return next( '/undefined/invalid-url');
    // }
    if (authRequired && !loggedIn) {

        return next( '/' + localStorage.getItem('schoolName') + '/login');
    }
    if(loggedIn && !authRequired) {

        return next( '/' + localStorage.getItem('schoolName'));
    }


    next();
})



export default router




