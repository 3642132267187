<template>
  <div class="col">

    <div class="card card-profile-1 mb-4">
        <div class="card-header text-initial">
                <h4><i class="fas fa-table"></i> {{$t('Attendance.text')}}</h4>
            </div>

      <div class="card-body text-center">
    
        <div v-if="Object.keys(dashboardData).length" class="ul-widget__body">
          <div class="ul-widget1">
            <table v-if="isStudent()" class="table table-bordered">
              <thead>
              <th>#</th>
              <th>{{$t('Date.text')}}</th>
              <th v-if="dashboardData.attendanceModel=='subject'">{{$t('Subject.text')}}</th>
              <th>{{$t('Attendance.text')}}</th>
              </thead>
              <tbody>
              <tr v-for="(attendance,index) in dashboardData.studentAttendance" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{attendance.date}}</td>
                <td v-if="dashboardData.attendanceModel=='subject'">{{attendance.subject}}</td>
                <td>
                  
                  <div>
                    <span v-if="attendance.status =='0'" class="badge badge-label badge-danger p-3">{{$t('Absent.text')}}</span>
                    <span v-if="attendance.status =='1'" class="badge badge-label badge-success p-1 font-boldest">{{$t('Present.text')}}</span>
                    <span v-if="attendance.status =='2'" class="badge badge-label badge-warning p-1 font-boldest">{{$t('Late.text')}}</span>
                    <span v-if="attendance.status =='3'" class="badge badge-label badge-warning p-1 font-boldest">{{$t('LateExecuse.text')}}</span>
                    <span v-if="attendance.status =='4'" class="badge badge-label badge-danger p-1 font-boldest">{{$t('earlyDismissal.text')}}</span>
                    <span v-if="attendance.status =='9'" class="badge badge-label badge-success p-1 font-boldest">{{$t('acceptedVacation.text')}}</span>
                  </div>

                </td>
              </tr>
              </tbody>
            </table>

            <div v-if="isParent()">
              <div v-for="(studentAttendance, index) in dashboardData.studentAttendance" class="box col-xs-12" :key="index">
                <div class="box-header">
                  <h3 class="box-title">{{studentAttendance.n.name}}</h3>
                </div>
                <div class="box-body table-responsive">
                  <table class="table table-bordered table-hover">
                    <tbody>
                    <tr>
                      <th>{{$t('Date.text')}}</th>
                      <th>{{$t('Attendance.text')}}</th>
                    </tr>
                    <tr v-for="(studentAttendance2,index) in studentAttendance.d" :key="index">
                      <td>{{studentAttendance2.date}}</td>
                      <td>
                        <div>
                          <span v-if="studentAttendance2.status =='0'" class="badge badge-label badge-danger p-3">{{$t('Absent.text')}}</span>
                          <span v-if="studentAttendance2.status =='1'" class="badge badge-label badge-success p-1 font-boldest">{{$t('Present.text')}}</span>
                          <span v-if="studentAttendance2.status =='2'" class="badge badge-label badge-warning p-1 font-boldest">{{$t('Late.text')}}</span>
                          <span v-if="studentAttendance2.status =='3'" class="badge badge-label badge-warning p-1 font-boldest">{{$t('LateExecuse.text')}}</span>
                          <span v-if="studentAttendance2.status =='4'" class="badge badge-label badge-danger p-1 font-boldest">{{$t('earlyDismissal.text')}}</span>
                          <span v-if="studentAttendance2.status =='9'" class="badge badge-label badge-success p-1 font-boldest">{{$t('acceptedVacation.text')}}</span>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "attendance",
  data () {
    return {
    }
  },
  computed: {
    ...mapState("dashboard", {
      dashboardData: "dashboardData",
    }),
  }
}
</script>

<style scoped>

</style>