<template>

  <div class="col-lg-6 col-xl-6" v-if="!isMinisterial()">
    <div class="card">

        <div class="card-header text-initial">
            <h4><i class="fas fa-user-graduate"></i> {{$t('studentLeaderboard.text')}}</h4>
        </div>
      <div class="card-body">
        <div v-if="Object.keys(dashboardData).length" class="ul-widget__body">
          <div class="ul-widget1">
            <div class="ul-widget4__item ul-widget4__users" v-for="(student,index) in dashboardData.studentLeaderBoard"
                 :key="index">
              <div class="ul-widget4__img">
                <img id="userDropdown" :src="student.image" alt="" data-toggle="dropdown"
                     aria-haspopup="true" aria-expanded="false"/>
              </div>
              <div class="ul-widget2__info ul-widget4__users-info">
                <a class="ul-widget2__title" href="#">
                  {{ student.fullName }}
                </a>
              </div>
              <span class="ul-widget4__number font-weight-bold text-success">
               {{ student.isLeaderBoard }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "students",
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  computed: {
    ...mapState('dashboard', {
      dashboardData: 'dashboardData',
    }),
  }
}
</script>

<style scoped>

</style>