<template>
  <div v-if="startLoading" class="row text-center">
    <div class="col-md-12">
      <div class="loader-bubble loader-bubble-primary"></div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    startLoading: {
      type: Boolean,
      default: false
    },
  },
}
</script>