<template>
  <div class="row" v-if="Object.keys(dashboardData).length">
    <div class="col-md-12">
      <div class="card mb-4 o-hidden">
        <div class="card-body">
          <div class="calendar">
            <Calendar
                class="custom-calendar max-w-full"
                :locale="$i18n.locale"
                @update:from-page="pageChange"
                :first-day-of-week="dayIndex"
                :masks="masks"
                :attributes="filterEvents"
                disable-page-swipe
                is-expanded>
              <template
                  v-slot:day-content="{ day, attributes }"
                  class="flex flex-col h-full z-10 overflow-scroll"
                  :class="day.year"
              >
                <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
                <div class="flex flex-col h-full z-10 overflow-hidden">
                  <div class="flex-grow overflow-y-scroll overflow-x-auto">
                   <p v-for="event in attributes"  :key="event.key + day.day" :style="{'background-color': event.customData.backgroundColor, 'color': event.customData.textColor}">
                     <router-link class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                                  :style="{'color': event.customData.textColor}"
                                  :to="{name: event.customData.eventType , state: {id: event.customData.eventId} }">
                       {{ event.customData.title }}
                     </router-link>
                   </p>
                  </div>
                </div>
              </template>
            </Calendar>
          </div>
        </div>
      </div>
    </div>
  </div><!-- end of main-content -->

</template>

<script>
import {Calendar} from 'v-calendar';
import {mapState} from "vuex";

export default {
  components: {
    Calendar,
  },
  data() {
    return {
      dateFrom: '',
      dateTo: '',
      events: [],
      masks: {
        weekdays: 'WWW',
      },
    }
  },

  methods: {
    loadEvents() {
      this.$axios.get('calender?start=' + this.dateFrom + '&end=' + this.dateTo)
          .then(response => {
            this.events = response.data
          });
    },
    pageChange(obj) {
      this.dateFrom = `1-${obj.month}-${obj.year}`;
      this.dateTo = `30-${obj.month}-${obj.year}`;
      this.loadEvents()
    }
  },
  computed: {
    filterEvents() {
      if (this.events && this.events.length) {
        return [
          ...this.events.map(event => (
              {
                key: event.id,
                customData: {
                  title: event.title,
                  backgroundColor: event.backgroundColor,
                  textColor: event.textColor,
                  url: event.url,
                  allDay: event.allDay,
                  eventType: event.eventType,
                  eventId: event.eventId,
                },
                dates: new Date(event.start.split('-')[2], event.start.split('-')[1] - 1, event.start.split('-')[0]),
              }
          )),
        ];
      }
      return [];
    },
    ...mapState('dashboard', {
      dashboardData : 'dashboardData',
    }),
    dayIndex() {
      let day = 1;
      if(this.dashboardData.country == 'TR') {
        day = 2;
      }
      return day
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.loadEvents();
    },
  }
}

</script>

<style scoped>


::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  display: none;
}

/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
}

/deep/ .custom-calendar.vc-container .vc-header {
  padding: 10px 0;
}

/deep/ .custom-calendar.vc-container .vc-weeks {
  padding: 0;
}

/deep/ .custom-calendar.vc-container .vc-weekday {
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0;
}

/deep/ .custom-calendar.vc-container .vc-day {
  padding: 0 5px 3px 5px;
  color: red !important;
  text-align: left;
  height: var(--day-height);
  min-width: var(--day-width);
  overflow-y: scroll !important;
}

/deep/ .custom-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: var(--day-border);
}

/deep/ .custom-calendar.vc-container .vc-day:not(.on-bottom).weekday-1 {
  border-bottom: var(--day-border-highlight);
}

/deep/ .custom-calendar.vc-container .vc-day:not(.on-right) {
  border-right: var(--day-border);
}

/deep/ .custom-calendar.vc-container .vc-day-dots {
  margin-bottom: 5px;
}

.overflow-scroll {
  overflow-y: scroll;
}
</style>