<template>
  <div class="row">
    <div class="col-md-6">
      <div class="p-4">
        <div class=" text-center mb-4">
          <img :src="`${publicPath}assets/images/ultimate-transparent.png`" width="150" alt="">
        </div>
        <h1 class="mb-3 text-18">{{$t('restorePwd.text')}}</h1>


        <form @submit.prevent="signIn" class="text-initial">
          <div class="form-group">
            <label for="email">{{$t('userNameOrEmail.text')}}</label>
            <input class="form-control form-control-rounded" id="email" type="text" required v-model="form.email">
          </div>
          <button class="btn btn-rounded btn-primary btn-block mt-2">{{$t('Send.text')}}</button>
        </form>
        <div class="mt-3 text-initial">
          <router-link class="text-muted" :to="{name : 'Login'}">
            <u>{{$t('loginToAccount.text')}}</u>
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-md-6 text-center small-bg" :style="{backgroundSize:'cover' ,backgroundImage: 'url(' + `${publicPath}assets/images/photo-long-3.jpg `+ ')' }">
      <div class="pr-3 auth-right">
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      form:{},
      publicPath: process.env.BASE_URL,
      register:process.env.VUE_APP_REGISTER,
      checkLogin:false,
      checkLicenseInvalid:false,
      message:'',
    }
  },
  mounted(){
    this.checkLicense();
  },
  methods: {
    signIn() {
      console.log(this.$store.dispatch('login',this.form));


    },
    checkLicense() {
      this.$axios.get('/website/check')
          .then((response) => {
            if(response.data.message == 'Invalid Url') {
              this.checkLogin = false;
              this.checkLicenseInvalid = true;
              this.message = 'Invalid Url';

            }else {
              this.checkLogin = true;
              this.checkLicenseInvalid = false;
            }
          });
    }
  },

  computed:{
    invalidData() {
      return this.$store.state.loggedIn
    },

  }
}
</script>

<style scoped>
.small-bg {
  background-position: bottom;
  background-size: cover;
}
</style>
